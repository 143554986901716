
import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';
import {apiSlice} from '../api/apiSlice';

//api/MiswebApi/Misweb/

const airTop5AirlinesAdapter=createEntityAdapter();

const initialState=airTop5AirlinesAdapter.getInitialState()

export const airTop5AirlinesApiSlice=apiSlice.injectEndpoints({

    endpoints:builder=>({
        getTop5Airlines:builder.query({
            query:(arg)=>({
                    url:'GetTop5Airlines',
                    method:'POST',
                    body:arg
                }),
            transformResponse:responseData=>{
 
                return airTop5AirlinesAdapter.setAll(initialState,responseData)
            },
            providesTags: ['AirNumbers']
        })
    })
})

export const {
    useGetTop5AirlinesQuery
}=airTop5AirlinesApiSlice

export const selectTop5AirlinesResult=airTop5AirlinesApiSlice.endpoints.getTop5Airlines.select();

const selectTop5AirlinesData=createSelector(
    selectTop5AirlinesResult,
    airTop5AirlinesResult=>airTop5AirlinesResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring

export const {
    selectAll:selectTop5Airlines

}=airTop5AirlinesAdapter.getSelectors(state=>selectTop5AirlinesData(state)??initialState)