import React from 'react';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { useSearchParams } from 'react-router-dom';


const SilentLogOut =()=>{

    const [searchParams] = useSearchParams();

    let rtn=searchParams.get('change');
    let content=""

   if(rtn == 'true')
    {
        content="Password is changed. You now need to sign in"
    }
    else 
    {
        content="You are now signed out. If you wish to continue please sign in again"
    }
    
    return(
    
        <Container maxWidth={false} alignItems="center" ><Alert sx={{  verticalAlign: 'middle',display: 'inline-flex',alignItems:"center"}}>{content}</Alert></Container>
        )

}

export default SilentLogOut