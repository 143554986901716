
import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';

import {apiSlice} from '../api/apiSlice';

const clientsAdapter=createEntityAdapter();

const initialState=clientsAdapter.getInitialState()

export const clientApiSlice=apiSlice.injectEndpoints({

//returns a list of clients for specific manager
    endpoints:builder=>({
      
        getclientList:builder.query({
            query:()=>({
                    url:'GetClients'
                }),
            transformResponse:responseData=>{
      
                return clientsAdapter.setAll(initialState,responseData)
            },
            providesTags: ['clients']
        })
    })
    })

export const {
    useGetclientListQuery
}=clientApiSlice

export const selectClientsResult=clientApiSlice.endpoints.getclientList.select();

const selectClientsData=createSelector(
    selectClientsResult,
    clientResult=>clientResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring

export const {
    selectAll:selectclients
}=clientsAdapter.getSelectors(state=> selectClientsData=createSelector((state)??initialState)
)

