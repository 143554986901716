
import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';
import {apiSlice} from '../api/apiSlice';

const custNrAdapter=createEntityAdapter();

const initialState=custNrAdapter.getInitialState()

export const custNrApiSlice=apiSlice.injectEndpoints({


    endpoints:builder=>({
      
        getCustNr:builder.query({
            query:(arg)=>({
                    url:'GetCustomerNrs',
                    method:'POST',
                    body:arg
                }),
            transformResponse:responseData=>{
      
                return custNrAdapter.setAll(initialState,responseData)
            },
            providesTags: ['CustNrs']
        })
    })
    })

export const {
    useGetCustNrQuery
}=custNrApiSlice

export const selectCustNrResult=custNrApiSlice.endpoints.getCustNr.select();

const selectCustNrData=createSelector(
    selectCustNrResult,
    custNrResult=>custNrResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring

export const {
    selectAll:selectCustNr
}=custNrAdapter.getSelectors(state=>selectCustNrData(state)??initialState)