import { Container,Grid} from '@mui/material';
import SegmentByFareBasisTable from './../../components/Tables/Air/SegmentFareBasis';
import TableAirByCityPair from  './../../components/Tables/Air/ByCityPair'
import TableAirByAirline from  './../../components/Tables/Air/ByAirline';
import TableAirALLEmissions from './../../components/Tables/Air/AllEmissions';
//import AirportDataMapResp from './../../components/Maps/AirMapResponsive';
import AirMapResponseiveFiltered from './../../components/Maps/AirMapResponsiveFiltered';
import CardNumbers from '../../components/Card/CardAirNumbers';


const Air = () => {

    return (

    <Container maxWidth={false} >
         <Grid
          container
          spacing={2}
          alignContent>
            <CardNumbers/>
            <Grid item  lg={4}
                md={4}
                xl={4}><TableAirByAirline/></Grid>
            <Grid item lg={8}
                md={8} xl={8}><AirMapResponseiveFiltered /></Grid>
            <Grid item  lg={4}
                md={4}
                xl={4}><TableAirByCityPair/></Grid>
            <Grid item  lg={4}
                md={4}
                xl={4}><SegmentByFareBasisTable/></Grid>
            <Grid item  lg={4}
                md={4} xl={4}>
                <Grid item  lg={12}
                    md={12}
                    xl={12}><TableAirALLEmissions/></Grid>
            </Grid>
        </Grid>
    </Container>

    );
};

export default Air;
