import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';
import {apiSlice} from '../api/apiSlice';

//api/MiswebApi/Misweb/

const airCityPairsAdapter=createEntityAdapter();

const initialState=airCityPairsAdapter.getInitialState()

export const airCityPairsApiSlice=apiSlice.injectEndpoints({

    endpoints:builder=>({
        getCityPairs:builder.query({
            query:(arg)=>({
                    url:'GetAirByCityPair',
                    method:'POST',
                    body:arg
                }),
            transformResponse:responseData=>{
      
                return airCityPairsAdapter.setAll(initialState,responseData)
            },
            providesTags: ['AirCityPairs']
        })
    })
})


export const {
    useGetCityPairsQuery
}=airCityPairsApiSlice

export const selectCityPairsResult=airCityPairsApiSlice.endpoints.getCityPairs.select();

const selectCityPairsData=createSelector(
    selectCityPairsResult,
    airCityPairsResult=>airCityPairsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring

export const {
    selectAll:selectCityPairs

}=airCityPairsAdapter.getSelectors(state=>selectCityPairsData(state)??initialState)