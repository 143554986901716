import {apiSlice} from '../api/apiSlice';

export const mapManClientApiSlice=apiSlice.injectEndpoints({
    
    endpoints:builder=>({
        addManagerMapClient:builder.mutation({
            query:(arg)=>({
                    url:'MapManagerClients',
                    method:'POST',
                    body:arg
                }),
                invalidatesTags: ['ManagerClients']  
            }),
        deleteManagerMapClient:builder.mutation({
            query:(arg)=>({
                    url:'DeleteMapManagerClients',
                    method:'POST',
                    body:arg
                }),
                invalidatesTags: ['ManagerClients']  
            })
        
        })
})

export const {useAddManagerMapClientMutation,useDeleteManagerMapClientMutation} = mapManClientApiSlice

