import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import { navbarStyles } from '../styles';


const JustLogIn =(props)=>{

const handleRouteChange=(route)=>{
    props.navigateToRoute(route);
}

const text = {
    fontSize: '1.3rem'
};

    
        return(<>
                <ListItem
                    button
                    onClick={() => handleRouteChange('/login')}>
                        <ListItemIcon  
                        sx={navbarStyles.icons}>{null}</ListItemIcon>
                        <ListItemText
                        primaryTypographyProps={{ style:text }}
                        primary={"Sign In"}/>
                </ListItem>
                   
                   </>
            )
}

export default JustLogIn;
