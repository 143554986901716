import {useEffect, useState} from "react";
import {TimeoutWarningModal} from "./TimeoutWarningModal"
import { addEventListeners,  removeEventListeners } from './eventListenerUtil'
import { useOidc } from "@axa-fr/react-oidc";



export const TimeoutLogic = () => { 
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const {logout,isAuthenticated} = useOidc();

  useEffect(() => {

    const createTimeout1 = () => setTimeout(()=>{ 
      setWarningModalOpen(true);
    },1000 * 60 * 5)

    const createTimeout2 = () => setTimeout(() => {
    // sign out function here
     logout("/loggedout")
    },1000 * 60 * 10)

    const listener = () => {
      if(!isWarningModalOpen){
        clearTimeout(timeout)
        timeout = createTimeout1();
      }
    } 

    // Initialization
    let timeout = isWarningModalOpen  ? createTimeout2() : createTimeout1()
    addEventListeners(listener);

    // Cleanup
    return () => {
      removeEventListeners(listener);
      clearTimeout(timeout);
    }
  },[isWarningModalOpen])

  return (
    <div>
      {(isWarningModalOpen && isAuthenticated) && (
        <TimeoutWarningModal 
          isOpen={isWarningModalOpen}
          onRequestClose={() => setWarningModalOpen(false)}
        />
        )
      }
    </div>
  ) 
}