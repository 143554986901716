
import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';
import {apiSlice} from '../api/apiSlice';

const divNrAdapter=createEntityAdapter();

const initialState=divNrAdapter.getInitialState()

export const divNrApiSlice=apiSlice.injectEndpoints({


    endpoints:builder=>({
      
        getDivNr:builder.query({
            query:(arg)=>({
                    url:'GetDivisions',
                    method:'POST',
                    body:arg
                }),
            transformResponse:responseData=>{
      
                return divNrAdapter.setAll(initialState,responseData)
            },
            providesTags: ['DivNrs']
        })
    })
    })

export const {
    useGetDivNrQuery
}=divNrApiSlice

export const selectDivNrResult=divNrApiSlice.endpoints.getDivNr.select();

const selectDivNrData=createSelector(
    selectDivNrResult,
    divNrResult=>divNrResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring

export const {
    selectAll:selectDivNr
}=divNrAdapter.getSelectors(state=>selectDivNrData(state)??initialState)