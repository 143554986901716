
import { useSelector } from 'react-redux';
import {selectParameters} from './../../../../features/reportparameters/parametersSlice';
import {useGetTop5AirlinesQuery} from './../../../../features/air/airTop5AirlinesSlice'

import Doughnuts  from './../../Doughnuts';
import Error from '../../../Error';


const Top5AirlineDoughnut=(props)=> {
  const para=useSelector(selectParameters);
  
    const {   
        data,
        isLoading,
        isSuccess,
        isError,
        error
      } = useGetTop5AirlinesQuery(para);
      
    let dataState=[]
      if(isSuccess) 
        { 
          dataState=data['entities']['undefined']

        }
      let chartData=[];
      let chartLabels=[];
      let devices=[];

      if(isSuccess && dataState) 
      { 
        chartData=dataState.map(d=>(d.trips))
        chartLabels=dataState.map(d=>(d.airlineName ));
        devices=dataState.map(d=>({"title":d.airlineName,"value":d.trips,"color":'red'}));
      }
    

      if(isLoading)
        {return (<p>...Loading</p>)}
      else if (isSuccess && dataState) 
        {
          return (
            <Doughnuts title={'Airlines Top Trips'} chartData={chartData} labeldata={chartLabels} devices={null}/>
          )
        }
      else
        {
          return(<Error ErrorMsg={error}/>);
        }
  }

export default Top5AirlineDoughnut;