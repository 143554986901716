import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DataToExcel from './../../../../utils/DataToExcel';
import { styled } from '@mui/material';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const SegmentFareBasisTable=(props)=>{

    let data=props.data;

    
    return (

      <TableContainer sx={{ maxHeight: 440 }}>
           <DataToExcel dataForExcel={data} fileName={"Farebasis"}/>
      <Table  size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Carrier</TableCell>
                <TableCell align="right">Fare Basis</TableCell>
                <TableCell align="right">Segments Nr</TableCell>            
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row,index) => (
              <StyledTableRow key={index}>
                  <TableCell align="left">{row.carrier}</TableCell>
                  <TableCell align="right">{row.fareBasis}</TableCell>
                  <TableCell align="right">{row.totalTrips}</TableCell>
              </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    );
  }

export default SegmentFareBasisTable



