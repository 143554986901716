
import {selectParameters} from './../../../../features/reportparameters/parametersSlice';
import {useSelector} from 'react-redux';
import {useGetAdvDaysQuery} from './../../../../features/air/airAdvDaysSlice';
import Spinner from './../../../../utils/Spinner'
import Pies  from './../../Pie';
//import PieD3 from './../../PieD3';
import Error from '../../../Error';

const AdvanDaysPie = (props) => {
    const para=useSelector(selectParameters);
    const {   
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetAdvDaysQuery(para)

    let dataState=[];
    if(isSuccess) 
        { 
        dataState=data['entities']['undefined']
        }

    let chartData=[];
    let chartLabels=[];
    let devices=[];

    if(isSuccess && dataState) 
    { 

      chartData=dataState.map(d=>(d.trips))
      chartLabels=dataState.map(d=>(d.advDays ));
      devices=dataState.map(d=>({"title":d.advDays,"value":d.trips,"color":'red'}));
    }

    if(isLoading)
        {
            return (<Spinner msg={'Loading'}/>)
        }
    else if (isSuccess && dataState) 
        {
            return (<Pies title={'Advanced Days'} chartData={chartData} labeldata={chartLabels} devices={null}/>)
        }
    else
        {
           return(<Error ErrorMsg={error}/>);
        }

}
  
  export default AdvanDaysPie;