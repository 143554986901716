import {useRef,useEffect,useState} from 'react';
import * as d3 from 'd3'
import useResizeObserver from "../../useResizeObserver";
import Card  from '@mui/material/Card'; 
import CardHeader  from '@mui/material/CardHeader'; 
import CardContent  from '@mui/material/CardContent'; 
import Divider  from '@mui/material/Divider'; 
import Paper  from '@mui/material/Paper'; 
import Typography  from '@mui/material/Typography'; 
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import './../styles.css';


const AirportMap = (props) => {
  const property="pop_est"
  const svgRef = useRef();
  const wrapperRef = useRef();
  const dimensions = useResizeObserver(wrapperRef);
  const [selectedCity, setSelectedCity] = useState(null);
  const [openDialog,setOpenDialog]=useState(false);


  const cities = props.data;

  const handleMarkerClick = i => {
    let inD=i-1

    setSelectedCity(cities[inD]);
    setOpenDialog(true);
  }

  const closeDialog =()=>{
    setOpenDialog(false);
    }

  
  // will be called initially and on every data change ATTN! The whole map is drawn in useEffect!!
  useEffect(() => {

    const svg = d3.select(svgRef.current);
  
    const zoom = d3.zoom()
    .scaleExtent([1, 8])
    .on('zoom', function(event) {
      const { transform } = event;
      svg.selectAll('.country')
        .attr('transform', transform);
      svg.selectAll("circle")
        .attr('transform', transform)
        .attr('r', circleR / transform.k); 
    });
  
    const minProp = d3.min(props.mapdata.features, feature => feature.properties[property]);
    const maxProp = d3.max(props.mapdata.features, feature => feature.properties[property]);
    const colorScale = d3.scaleLinear()
    .domain([maxProp,minProp])
    .range(['#02175A','#00408A','#0069AD','#0093BF','#00BBC0','#00E3B5']);

    // use resized dimensions
    // but fall back to getBoundingClientRect, if no dimensions yet.

    const { width, height } =
      dimensions || wrapperRef.current.getBoundingClientRect();
      svg.height=height;
      svg.width=width; 
      svg.call(zoom);


    const projection = d3.geoMercator()
      .fitSize([svg.width, svg.height],props.mapdata)
      .translate([width / 1.95, height /1.8]) 
      .center([0, 5])
      .scale(120)
      .rotate([-10,0])
      .precision(150);

    // takes geojson data,
    // transforms that into the d attribute of a path element
    const path = d3.geoPath().projection(projection);

    const circleR = 2;


  // render each country
    svg
      .selectAll(".country")
      .data(props.mapdata.features)
      .join("path")
      .attr("class", "country")
      .transition()
      .attr("fill", feature => colorScale(feature.properties[property]))
      .attr("d", feature => path(feature))


    //render cities
    svg.selectAll("circle").remove() // this is needed otherwise the circles get applied multiple times 
    svg.selectAll("circle")
      .data(cities)
      .enter()
      .append("circle")
      .attr("cx", function (d) { return projection(d.coordinates)[0]; })
      .attr("cy", function (d) { return projection(d.coordinates)[1]; })
      .attr("class","markers")
      .attr("r", circleR)
      .attr("fill", "#ffffff")
      .attr("stroke","#ffffff")
      .on("click",(event,data)=>{
          handleMarkerClick(data.id);
      })

  }, [props.data, dimensions]);

  const DisplayCard =(props)=>{

    return (
        <Paper elevation={0}>
              <Typography variant='body1'> Air Trips: {props.city.trips.toLocaleString()} </Typography>
              <Typography variant='body1'> Air Spend: {props.city.spend.toLocaleString()} </Typography>
        </Paper>
    )
  }

  return (
    <>
        <Card sx={{height:"100%"}}>
            <CardHeader title={props.title}/>
            <Divider />
                <CardContent 
                sx={{ 
                  height:"100%",
                  padding: 5,
                  "&:last-child": {
                    paddingBottom: 10
                  }}}  ref={wrapperRef}>   
                   <svg ref={svgRef}  ></svg>
                          <Dialog
                                open={openDialog}
                                onClose={closeDialog}
                              >
                            <DialogTitle sx={{fontSize:'1rem'}}>{selectedCity && selectedCity.name }</DialogTitle>
                            <DialogContent>
                              <DisplayCard city={selectedCity}/>
                            </DialogContent>
                          </Dialog>    
                </CardContent>
        </Card>
    </>
  );
}

export default AirportMap
