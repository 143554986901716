
import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';
import {apiSlice} from '../api/apiSlice';

//api/MiswebApi/Misweb/

const airAirlinesAdapter=createEntityAdapter();

const initialState=airAirlinesAdapter.getInitialState()

export const airAirlinesApiSlice=apiSlice.injectEndpoints({

    endpoints:builder=>({
        getAirlines:builder.query({
            query:(arg)=>({
                    url:'GetAirByAirline',
                    method:'POST',
                    body:arg
                }),
            transformResponse:responseData=>{
           
                return airAirlinesAdapter.setAll(initialState,responseData)
            },
            providesTags: ['AirNumbers']
        })
    })
})

export const {
    useGetAirlinesQuery
}=airAirlinesApiSlice

export const selectAirlinesResult=airAirlinesApiSlice.endpoints.getAirlines.select();

const selectAirlinesData=createSelector(
    selectAirlinesResult,
    airAirlinesResult=>airAirlinesResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring

export const {
    selectAll:selectAirlines

}=airAirlinesAdapter.getSelectors(state=>selectAirlinesData(state)??initialState)