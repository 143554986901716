import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';
import {apiSlice} from '../api/apiSlice';

//api/MiswebApi/Misweb/

const hotelDestinationsAdapter=createEntityAdapter();

const initialState=hotelDestinationsAdapter.getInitialState()

export const hotelDestinationsApiSlice=apiSlice.injectEndpoints({

    endpoints:builder=>({
        getHotelDestinations:builder.query({
            query:(arg)=>({
                    url:'GetHotelDest',
                    method:'POST',
                    body:arg
                }),
            transformResponse:responseData=>{
           
                return hotelDestinationsAdapter.setAll(initialState,responseData)
            },
            providesTags: ['HotelDest']
        })
    })
})

export const {
    useGetHotelDestinationsQuery
}=hotelDestinationsApiSlice

export const selectHotelDestinationsResult=hotelDestinationsApiSlice.endpoints.getHotelDestinations.select();

const selectHotelDestinationsData=createSelector(
    selectHotelDestinationsResult,
    hotelDestinationsResult=>hotelDestinationsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring

export const {
    selectAll:selectHotelDestinations

}=hotelDestinationsAdapter.getSelectors(state=>selectHotelDestinationsData(state)??initialState)