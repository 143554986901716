import { createSlice } from '@reduxjs/toolkit'

const initialState={
    reportParameter:{}
}

const parametersSlice = createSlice({
    name: 'parameters',
    initialState,
    reducers:{
        setParameters:(state,action)=>{
            state.reportParameter=action.payload
        }
    }
})



export const selectParameters = (state)=> state.parameters.reportParameter
export const {setParameters}=parametersSlice.actions
export default parametersSlice.reducer