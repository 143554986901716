
import { useState,useEffect} from "react";
import configData from './../../features/api/configURL.json';
import {useGetclientManagerListQuery} from './../../features/admin/managersApiSlice';
import {useGetclientListQuery} from './../../features/admin/clientsApiSlice';
import {useAddManagerMapClientMutation,useDeleteManagerMapClientMutation}  from '../../features/admin/mapManClientApiSlice';
import {useGetmanagersClientsQuery} from '../../features/reportparameters/managersClientsApiSlice';
import Button  from "@mui/material/Button";
import SendIcon from '@mui/icons-material/Send';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import Grid from '@mui/material/Unstable_Grid2'
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles"


const Admin=()=>{


const [manager,setManager]=useState('');
const [email,setEmail]=useState({});
const [mangerName,setMangerName]=useState('')
const [selectedClients,setSelectedClients]=useState([]);
const [addManagerMapClient,responseAdd]=useAddManagerMapClientMutation();
const [deleteManagerMapClient,responseDelete]=useDeleteManagerMapClientMutation();
const [dataSaved,setDataSaved]=useState(false);
const [skip, setSkip] = useState(true);

let selectOptionsManagerUsers;
let selectOptionsClients;

const theme = useTheme()

const { data: clientManagerList = [],isError:inErrorClientManager,  isFetching: clientManagerFetching ,isSuccess:clientManagerSuccess}=useGetclientManagerListQuery(); //gets the list of client managers
const { data: clientList = [],isError:inErrorClient,  isFetching: clientFetching ,isSuccess:clientSuccess}= useGetclientListQuery(); //gets List of all Clients
const { data: clientManagerClients = [],isError:inErrorClientManagerClients,  isFetching: clientManagerClientsFetching ,isSuccess:clientManagerClientsSuccess}=useGetmanagersClientsQuery(email,{skip}); //gets list of clients managed by Client MAnager

useEffect(()=>{
    if(responseAdd.status !=='' || responseAdd.status !=='undefined')
    {
        if (responseAdd.status ==='fulfilled'){
            setDataSaved(true)
        }
    }
},[responseAdd])


let managers =[]
if(clientManagerSuccess && clientManagerList)
{
    managers = Object.assign([], clientManagerList['entities']['undefined']);
    const topObj={email:"clear"}
        managers.push(topObj)
        selectOptionsManagerUsers=managers.map((man,index)=>{
        return( <MenuItem key={index} value={man.email}>{man.fullname}</MenuItem>)
    })
}

if(clientSuccess && clientList)
{
    let clients = Object.assign([], clientList['entities']['undefined']);
    const topObj={
        "corpCode":null,
        "corpname": "clear"
        }
        clients.push(topObj)
        selectOptionsClients=clients.map((cli,index)=>{
        return( <MenuItem key={index} value={cli.corpCode}>{cli.corpname}</MenuItem>)
    })
}

let i=1;
let selectedClientsList=[]
if(selectedClients.length>0) {selectedClients.map((item)=>{
    i++
    selectedClientsList.push( <ListItem key={i}><ListItemText  key={item} primary={item}/></ListItem> )
    })
}

let managersClientListData =[];
let managersClientListItem =[]

if(clientManagerClientsSuccess && clientManagerClients)
{
    managersClientListData = Object.assign([], clientManagerClients['entities']['undefined']); //list of clients already mapped to manager
  
    managersClientListItem=managersClientListData.map((cli,index)=>{
        return( <ListItem key={index} onClick={()=>deleteMapping(cli.corpCode)}><ListItemText  key={index} primary={cli.corpname} /></ListItem> )
    })
}



const clickGoToIdentity=()=>{
    window.location.href = configData.IDENTITY + "Login"
}

const handleOnChangeManager=(e)=>{
    let selName=managers.filter((man)=>man.email === e.target.value);
    setMangerName(selName[0]['fullname'])
    setManager(e.target.value);
    setEmail({...email,['email']:e.target.value});
    setSelectedClients([]);
}

const handleOnChangeClient=(e)=>{
    console.log(selectedClients)
    if (selectedClients.includes(e.target.value))
    return null
    else
    setSelectedClients([...selectedClients,e.target.value])
}

const handleSaveData=()=>{
    if (selectedClients.length >0)
    {
    let dataToSave=[]
    selectedClients.forEach(client=>{
                dataToSave.push({'email':manager,'corpcode':client})
            })
        addManagerMapClient(dataToSave)
    }
    else{
        alert("No clients are selected")
    }
}

const handleManagerFocus=()=>{
    if(dataSaved===true){
        setDataSaved(false)
    }
}

const showMappedClients=()=>{
    setSkip(false);
}

const deleteMapping=(value)=>{
    let delObj={};

    delObj.email=manager;
    delObj.corpcode=value;

    deleteManagerMapClient(delObj);
    setSkip(false);

}
const showSelectedManager=()=>{

    if (manager.length > 1){
        return mangerName + ':' + manager;
    }
    return null;
}

const styles={
    paper: {
        padding: theme.spacing(1),
        textAlign: "left",
        color: theme.palette.text.secondary
      }
}

return(
    <Container maxWidth={false} >
            <Grid  container
            spacing={2}
            alignContent>
                <Grid item xs={12}>
                    <Paper sx={styles.paper}> 
                        <Button
                        id="btnGoToIdentity"
                        onClick={()=>clickGoToIdentity()}
                        variant="contained"
                        sx={{ mt: 3, mb: 2 ,backgroundColor:'#02175A'}}>Go to Identity</Button>
                    </Paper>
                </Grid>
                <Grid item rowSpacing={3}  xs={6} >
                    <Paper sx={styles.paper}>                 
                            <InputLabel id="selectManager_label">Managers</InputLabel>
                            <Select  sx={{width:200}}
                                id="selectManager"
                                size="small"
                                value={manager}
                                label="Manager"
                                onChange={handleOnChangeManager}
                                onFocus={handleManagerFocus}
                            >                               
                                {selectOptionsManagerUsers} 
                            </Select> <br/>
                            <Typography variant="h6">Selected Manager</Typography>
                            <Typography variant="subtitle1">{showSelectedManager()}</Typography>
                            <Stack direction="row" spacing={2}>
                            <Button
                                id="btnSave"
                                onClick={()=>handleSaveData()}
                                variant="contained"
                                sx={{ mt: 3, mb: 2 ,backgroundColor:'#02175A'}} endIcon={dataSaved ===true ?<SendIcon/>:null}>Save</Button>
                            
                            
                            {manager.length > 1 ? 
                                <Button id="btnShowList" 
                                    onClick={()=>showMappedClients()}
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 ,backgroundColor:'#02175A'}}>Clients</Button>:null}
                            </Stack>
                            {managersClientListItem.length >0 ? <List sx={{ width: '100%', maxWidth: 360, color: 'black',bgcolor: 'background.paper',  }}>
                                {managersClientListItem}
                            </List>
                            :null
                            }  
                    </Paper>
                </Grid>
                <Grid item rowSpacing={3}  xs={6}>  
                    <Paper sx={styles.paper}>
                            <InputLabel id="selectClient-label">Clients</InputLabel>
                            <Select sx={{width:200} }
                            id="selectClient"
                                size="small"
                                label="Clients"
                                onChange={handleOnChangeClient}
                            >
                                {selectOptionsClients}          
                            </Select>
                    </Paper>
                    <Paper>
                    { selectedClientsList.length > 0 ? <List sx={{ width: '100%', maxWidth: 360, color: 'black',bgcolor: 'background.paper',  }}>
                                {selectedClientsList}
                            </List>
                            :null
                            }  
                    </Paper>
                </Grid>
                  
            </Grid>
    </Container>

)
}

export default Admin