import React from 'react';
import { useSelector } from "react-redux";
import {selectParameters} from './../../../../features/reportparameters/parametersSlice';
import {useGetHotelCitiesTop5Query} from './../../../../features/hotel/hotelCitiesTop5Slice'

import Doughnuts  from './../../Doughnuts';
import Error from '../../../Error';

const Top5HotelCitiesDoughnut=(props)=> {
  const para=useSelector(selectParameters);
  const {   
        data,
        isLoading,
        isSuccess,
        isError,
        error
      } = useGetHotelCitiesTop5Query(para);

      let dataState=[]

      if(isSuccess) 
      { 
         dataState=data['entities']['undefined']
      }

      let chartData=[];
      let chartLabels=[];
      let devices=[];

      if(isSuccess && dataState) 
      { 
        chartData=dataState.map(d=>(d.nrNights))
        chartLabels=dataState.map(d=>(d.hotelCity));
        devices=dataState.map(d=>({"title":d.hotelCity,"value":d.nrNights,"color":'red'}));
      }
    

      if(isLoading)
        {return (<p>...Loading</p>)}
      else if (isSuccess && dataState) 
        {
          return (
            <Doughnuts title={'Hotels Top Cities'} chartData={chartData} labeldata={chartLabels} devices={null}/>
          )
        }
      else
        {
          return(<Error ErrorMsg={error}/>);
        }
  }

export default Top5HotelCitiesDoughnut;