import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';
import {apiSlice} from '../api/apiSlice';

const managersClientsAdapter=createEntityAdapter();

const initialState=managersClientsAdapter.getInitialState()

export const managersClientsApiSlice=apiSlice.injectEndpoints({

//returns a list of clients for specific manager
    endpoints:builder=>({
      
        getmanagersClients:builder.query({
            query:(arg)=>({
                    url:'GetManagerClients',
                    method:'POST',
                    body:arg
                }),
            transformResponse:responseData=>{
      
                return managersClientsAdapter.setAll(initialState,responseData)
            },
            providesTags: ['ManagerClients']
        })
    })
    })

export const {
    useGetmanagersClientsQuery
}=managersClientsApiSlice

export const selectmanagersClientsResult=managersClientsApiSlice.endpoints.getmanagersClients.select();

const selectmanagersClientsData=createSelector(
    selectmanagersClientsResult,
    managersClientsResult=>managersClientsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring

export const {
    selectAll:selectmanagersClients
}=managersClientsAdapter.getSelectors(state=>selectmanagersClientsData(state)??initialState)