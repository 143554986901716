import { useSelector } from 'react-redux';
import { Card,CardHeader,CardContent,Divider,Box } from '@mui/material';
import {selectParameters} from './../../../../features/reportparameters/parametersSlice';
import {useGetHotelDestinationsQuery} from './../../../../features/hotel/hotelDestinationsSlice';

import Spinner from './../../../../utils/Spinner';


import HotelDestinationTable from './Table';


const TableHotelDestinations =(props)=>{
  const para=useSelector(selectParameters);
  const {   
      data,
      isLoading,
      isSuccess,
      isError,
      error

    } = useGetHotelDestinationsQuery(para);
 

    let tableData={}

    if(isSuccess) 
    { 

      tableData=data['entities']['undefined']
    }

    let content;

      if (isLoading) {
            content = <Spinner msg={'Loading'}/>;
        } else if (isSuccess && tableData) {
            content =<HotelDestinationTable data={tableData}/>
        } else if (isError) {
            content = <p>{error}</p>;
    }
  
    return (
      <Card>
        <CardHeader title='Hotel Destinations' />
        <Divider />
          <CardContent>
            <Box
                sx={{
                    height: 445,
                    position: 'relative'
                }}>
              {content}
            </Box>
          </CardContent>
      </Card>
  )
}

export default TableHotelDestinations
