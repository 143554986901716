import { useSelector } from "react-redux";
import {selectParameters} from './../../../features/reportparameters/parametersSlice';
import DisplayCard from './../Displaycard';
import Grid from  "@mui/material/Grid";
import Error from "../../Error";
import {useGetDashNumbersQuery} from './../../../features/dashboard/dashNumbersSlice';
//import { useOidcAccessToken } from '@axa-fr/react-oidc';


   
const AirNumberCards=(props)=>{
    //const {accessToken} = useOidcAccessToken()
    const para=useSelector(selectParameters);
    const {   
            data,
            isLoading,
            isSuccess,
            isError,
            error
        } = useGetDashNumbersQuery(para)

    let dashNumbers=[];
    
        if(isSuccess) 
        { 
           dashNumbers=data['entities']['undefined']
        }

    let cards=[];
    let ikey = 1
 
    let content=[];

        if (isLoading) {
            content = <p>"Loading..."</p>;
            } 
            else if (isSuccess && dashNumbers) {

                dashNumbers.forEach(el=>{

                for (const [key, value] of Object.entries(el)) {
                    ikey++
                    let string1 =key;
                    let string2="";
                    let stringIcon="";
                    let stringvalue='0';
                    
                    switch(string1.toLowerCase()){
                                case "flighttrips":
                                    string2="Number of flighttrips";
                                    stringIcon="dash";
                                    stringvalue=el.flighttrips
                                    break;
                                case "co2":
                                    string2="Total co2";
                                    stringIcon="dash";
                                    stringvalue=el.co2
                                    break;
                                case "traintrips":
                                    string2="Number of traintrips";
                                    stringIcon="refund";
                                    stringvalue=el.traintrips
                                    break;
                                case "hotelnights":
                                    string2="Number of hotelnights";
                                    stringIcon="change";
                                    stringvalue=el.hotelnights
                                    break;
                                case "carbookings":
                                    string2="Number of carbookings";
                                    stringIcon="change";
                                    stringvalue=el.carbookings
                                    break;
                                default: 
                                    string2="";
                            }

                cards.push(
                          
                    <Grid key={ikey} item lg={2} sm={2} xl={2} xs={12} style={{width:'100%'}}>
                    <DisplayCard  headtext={string1} subtext={string2} value={stringvalue} percent={'50'} sx={{ height: '100%' }}/>
                </Grid>
                
                        )
                } content=cards});
          

            }    
        else if (isError) {
        
            return(<Error ErrorMsg={error}/>);
            }
     
    return(content?content:null)
}






  export default AirNumberCards;