import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DataToExcel from './../../../../utils/DataToExcel';

import { styled } from '@mui/material';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const HotelChainsTable =(props)=>{
  
  let data=props.data;


    return(
  
   
        <TableContainer sx={{ maxHeight: 440 }}>
            <TableRow>
              <DataToExcel dataForExcel={data} fileName={"HotelChain"}/>
            </TableRow>
        <Table  size="small" stickyHeader aria-label="sticky table">
        <TableHead>
        <TableRow> <TableCell align="center"/> <TableCell align="center" colSpan={2}>This Year</TableCell> <TableCell align="center" colSpan={2}> Last Year</TableCell></TableRow>
          <TableRow>
            <TableCell align="left" width="40%">Chain</TableCell>
            <TableCell align="right" width="15%">Nights</TableCell>
            <TableCell align="right" width="15%">Spend</TableCell>
            <TableCell align="right" width="15%">Nights</TableCell>
            <TableCell align="right" width="15%">Spend</TableCell>
          
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row,index) => (
               <StyledTableRow key={index}>
              <TableCell align="left">{row.hotelChain}</TableCell>
              <TableCell align="right">{row.hotelChainRmNights}</TableCell>
              <TableCell align="right">{row.hotelChainRmSpend}</TableCell>
              <TableCell align="right">{row.hotelChainRmNightsLY}</TableCell>
              <TableCell align="right">{row.hotelChainRmSpendLY}</TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>

        
    )
}

export default HotelChainsTable;