import { createTheme } from '@mui/material';
import BentonSansBook from '../fonts/BentonSansBook.woff';
import BentonSansBold  from '../fonts/BentonSansBold.woff';
import BentonSansRegular  from '../fonts/BentonSansRegular.woff';


export const mistheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      xl: 1920
    }
  },
  palette: {
    alan: {
      main: "#ffffff",
      contrastText: "#02175A" //button text white instead of black
    }
  },
 
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'BentonSansRegular';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('BentonSansRegular'), local('BentonSansRegular'), url(${BentonSansRegular}) format('woff');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
/*    
    background:'#ebebeb' 
      color: '#02175A', GBT blue*/
    MuiDrawer: {
      styleOverrides: {
        root:{
        '& .MuiDrawer-paper': {
          width: 240,
          boxSizing: 'border-box',
          color: "white",
          background:'#02175A'
      }
      }
    }
    },

    MuiCardHeader:{
      styleOverrides:{
        root:{
          background:'#999999',
          fontFamily:BentonSansRegular
        },
        title:{
          color: 'white',
        },
      }
    },
    
    MuiCardContent:{
      styleOverrides:{
        root:{
          background:'#ebebeb'
        }
      },
      variants:[
        {
        props:{variant:'alan'},
        style:{
            background:'#999999'
          }
        }
      ]
    },

    MuiTableHead:{
      styleOverrides:{
        root: { 
          '.MuiTableCell-root': {
            color: '#02175A',
          },
        } 
      } 
    },

    MuiTableBody:{
      styleOverrides:{
          root:{
            '.MuiTableCell-root':{
            fontSize: '.70em'
          }
        }
      }
    },

    MuiFormLabel:{
      styleOverrides:{
        root:{
          '.MuiInputLabel-root':{
          fontSize: '.70em',
          color:'white'
        }
      }
    }
  },

  MuiInputBase: {
    styleOverrides:{
    input: {
      color:'white'
    },
  }
  },

  MuiTextField: {
    styleOverrides: {
      root: {
        "& .MuiOutlinedInput-root": {
          "& > fieldset": {
            borderColor: "white",
          },
        },
        "& .MuiOutlinedInput-root:hover": {
          "& > fieldset": {
            borderColor: "#89CFF0",
          }
        },
        "& .MuiOutlinedInput-root:focus": {
          "& > fieldset": {
            borderColor: "yellow",
          }
        },
        "& .MuiSelect-icon":{
          color:"white"
        }
      },
    },
  },

  MuiFormHelperText:{
    styleOverrides: {
      root: {
          color:"white"
        }
      }
  },


  MuiInput: {
      styleOverrides: {
        root: {
            fontSize: '.80em'
          }
        }
      },

 MuiFilledInput: {
  styleOverrides: {
        root: {
          backgroundColor: "black"
 }},},

  MuiTypography:{
      variants:[
        {
        props:{variant:'alan'},
        style:{
            fontSize: '.70em'
          }
        },
        {
          props:{variant:"sidebar"},
          style:{
            marginLeft:'20px',
            paddingTop:'20px',
            color: 'yellow',
          }
        }
          ]
      },
    },

    typography: {
    color:'#00175a',
    button: {
      fontWeight: 500
    },
    fontFamily:'BentonSansRegular,Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
   /*  '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"', */
    body1: {
      fontFamily:'BentonSansRegular',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.57
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.75
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.57
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 600,
      letterSpacing: '0.5px',
      lineHeight: 2.5,
      textTransform: 'uppercase'
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.66
    },
    h1: {
      fontWeight: 700,
      fontSize: '3.5rem',
      lineHeight: 1.375
    },
    h2: {
      fontWeight: 700,
      fontSize: '3rem',
      lineHeight: 1.375
    },
    h3: {
      fontWeight: 700,
      fontSize: '2.25rem',
      lineHeight: 1.375
    },
    h4: {
      fontWeight: 700,
      fontSize: '2rem',
      lineHeight: 1.375
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: 1.375
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem',
      lineHeight: 1.375
    }
  }}
)
