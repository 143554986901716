import { useSelector } from 'react-redux';
import { Card,CardHeader,CardContent,Divider,Box } from '@mui/material';
import {selectParameters} from './../../../../features/reportparameters/parametersSlice';
import {useGetAirSegmentDetailQuery} from './../../../../features/air/airSegmentDetailSlice';
import SegmentFareBasisTable from './Table';
import Spinner from './../../../../utils/Spinner';
import Error from '../../../Error';

const SegmentByFareBasisTable=(props)=>{

  const para=useSelector(selectParameters);


  const {   
      data,
      isLoading,
      isSuccess,
      isError,
      error
  } = useGetAirSegmentDetailQuery(para);

    let tableData={}

    if(isSuccess) 
    { 

      tableData=data['entities']['undefined']
    }

    let content;

      if (isLoading) {
            content = <Spinner msg={'Loading'}/>;
        } else if (isSuccess && tableData) {
            content =<SegmentFareBasisTable data={tableData}/>
        } else if (isError) {
            content = <Error ErrorMsg={error}/>;
    }

    return (
      <Card>
        <CardHeader title='Fare Basis' />
        <Divider />
          <CardContent>
            <Box
                sx={{
                    height: 445,
                    position: 'relative'
                }}>
              {content}
            </Box>
          </CardContent>
      </Card>
  )
}


 

export default SegmentByFareBasisTable