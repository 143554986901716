import {Bar} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Box  from '@mui/material/Box'; 
import Card  from '@mui/material/Card'; 
import CardHeader  from '@mui/material/CardHeader'; 
import CardContent  from '@mui/material/CardContent'; 
import Divider  from '@mui/material/Divider'; 
import { useTheme } from '@mui/material';



const Bars = (props) => {
  const theme = useTheme();  

  const myMonths=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

  const axisLabels = props.labeldata.map(l=> myMonths[l])


  const data = {
    datasets: [
      {
        backgroundColor: '#0069AD',
        barPercentage: 0.5,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 0.5,
        data: props.chartdata1, 
        label: 'This year',
        maxBarThickness: 10
      },
      {
        backgroundColor: 'red',
        barPercentage: 0.5,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 0.5,
        data: props.chartdata2,
        label: 'Last year',
        maxBarThickness: 10
      }
    ],
    labels: axisLabels
  };

  const options = {
    animation: true,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    xAxes: [
      {
        ticks: {
          fontColor: theme.palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 0
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        }
      }
    ],
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false,
        text: "",
        padding: {
          bottom: 30
        },
        weight: "bold",
        color: "#00325c",
        font: {
          size: 13
        },
        align: "start"
      },
      datalabels: {
        display: false,
        color: "white"
      }
    }
  };

  return (
    <Card>
      <CardHeader title={props.title} />
      <Divider />
      <CardContent>
        <Box
          sx={{
              height: 300,
              position: 'relative'
          }}>
            <Bar
              data={data}
              options={options}
              plugins={[ChartDataLabels]}
            />
      </Box>
      </CardContent>
    
    </Card>
  );
};
export default Bars