
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


import configData from './configURL.json';



const urlBase =configData.MISWEB_API_URL;

export const apiSlice=  createApi({
  
    reducerPath:'api',
    baseQuery:fetchBaseQuery(
        {
            baseUrl:urlBase,
            prepareHeaders:(headers)=>{
              
                headers.set('Accept','application/json')
                headers.set('Content-Type', 'application/json')
        
                return headers
                }
        }
        ),
    tagTypes: ['AdvanceDays', 'AirNumbers','TotalAirTripsArea','TotalAirTripsMonthly','AirDestinationsMap','AirSegmentDetail','dsTotalTripsMonthlyTrend','AirTicketBreakdown','AirTicketTypes',
     'AirCityPairs','Token','Top5Hotels','Top10HotelChains','Top5HotelCities','HotelMapDestinations','AirSegmentDetail','HotelChains','HotelDest','Hotels','HotelChains','LogIn','AirCO2All','AirCO2',
     'CountryLevelData','CustNrs','DivNrs','DashNumbers','ExchRate','ManagerClients'],
    endpoints:(builder)=>({})
})
