
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import LogInButton from './AuthLoginButton'

const Login = () => {
 
  return(
        <Container component="main" maxWidth="xs" sx={{ width: '20%' }} >
          <Box
              sx={{
                marginTop: 12,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>

            <Typography component="h1" variant="h5">
              Sign in/out
            </Typography>
                <LogInButton/>       
          </Box>

      </Container>

  )
}

export default Login