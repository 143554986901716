import { configureStore } from '@reduxjs/toolkit'
import {apiSlice} from './api/apiSlice';
import {apiIdentitySlice} from './api/apiIdentitySlice';
import reportParametersReducer from './reportparameters/parametersSlice';
import rolesReducer from './auth/rolesSlice';
import spinnerReducer from './reportparameters/spinnerSlice'; 
export const store = configureStore({
    reducer:{
        [apiSlice.reducerPath]:apiSlice.reducer,
        [apiIdentitySlice.reducerPath]:apiIdentitySlice.reducer,
        parameters:reportParametersReducer,
        spinner:spinnerReducer,
        userRoles:rolesReducer
    },
    middleware:getDefaultMiddleware=>
        getDefaultMiddleware({ serializableCheck: false}).concat(apiSlice.middleware)
});

export default store;