import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';
import {apiSlice} from '../api/apiSlice';

//api/MiswebApi/Misweb/

const hotelChainsAdapter=createEntityAdapter();

const initialState=hotelChainsAdapter.getInitialState()

export const hotelChainsApiSlice=apiSlice.injectEndpoints({

    endpoints:builder=>({
        getHotelChains:builder.query({
            query:(arg)=>({
                    url:'GetHotelChains',
                    method:'POST',
                    body:arg
                }),
            transformResponse:responseData=>{
           
                return hotelChainsAdapter.setAll(initialState,responseData)
            },
            providesTags: ['HotelChains']
        })
    })
})

export const {
    useGetHotelChainsQuery
}=hotelChainsApiSlice

export const selectHotelChainsResult=hotelChainsApiSlice.endpoints.getHotelChains.select();

const selectHotelChainsData=createSelector(
    selectHotelChainsResult,
    hotelChainsResult=>hotelChainsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring

export const {
    selectAll:selectHotelChains

}=hotelChainsAdapter.getSelectors(state=>selectHotelChainsData(state)??initialState)