import React from 'react';
import { useSelector } from 'react-redux';
import {selectParameters} from './../../../../features/reportparameters/parametersSlice';
import {useGetHotelPropertiesTop5Query} from './../../../../features/hotel/hotelPropertiesTop5Slice'
import Doughnuts  from './../../Doughnuts';
import Error from '../../../Error';

const Top5HotelDoughnut=(props)=> {
  const para=useSelector(selectParameters);

      const {   
        data,
        isLoading,
        isSuccess,
        isError,
        error
      } = useGetHotelPropertiesTop5Query(para);


      let dataState=[]

      if(isSuccess) 
      { 
         dataState=data['entities']['undefined']
      }
      let chartData=[];
      let chartLabels=[];
      let devices=[];

      if(isSuccess && dataState) 
      { 
        chartData=dataState.map(d=>(d.nrNights))
        chartLabels=dataState.map(d=>(d.hotel ));
        devices=dataState.map(d=>({"title":d.hotelCity,"value":d.nrNights,"color":'red'}));
      }
    

      if(isLoading)
        {return (<p>...Loading</p>)}
      else if (isSuccess && dataState) 
        {
          return (
            <Doughnuts title={'Hotels Top 5 Properties'} chartData={chartData} labeldata={chartLabels} devices={null}/>
          )
        }
      else
        {
          return(<Error ErrorMsg={error}/>);
        }
  }

export default Top5HotelDoughnut;