import { useSelector } from 'react-redux';
import { Card,CardHeader,CardContent,Divider,Box } from '@mui/material';
import {selectParameters} from '../../../../features/reportparameters/parametersSlice';
import {useGetAirCO2AllQuery} from '../../../../features/air/airCO2Slice';

import Spinner from '../../../../utils/Spinner';
import Error from '../../../Error';
import AirAllEmissionsTable from './Table'




const TableAirALLEmissions =(props)=>{
  const para=useSelector(selectParameters);
  const {   
      data,
      isLoading,
      isSuccess,
      isError,
      error

    } = useGetAirCO2AllQuery(para);
 

    let tableData={}

    if(isSuccess) 
    { 

      tableData=data['entities']['undefined']
    }

    let content;

      if (isLoading) {
            content = <Spinner msg={'Loading'}/>;
        } else if (isSuccess && tableData) {
            content =<AirAllEmissionsTable data={tableData}/>
        } else if (isError) {
          content = <Error erErrorMsg={error}/>;
    }
       
    return (
      <Card>
        <CardHeader title='Emissions' />
        <Divider />
          <CardContent>
            <Box
                sx={{
                    height:220,
                    position: 'relative'
                }}>
              {content}
            </Box>
          </CardContent>
      </Card>
  )
  }
 
  export default TableAirALLEmissions