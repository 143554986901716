import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';

import { apiSlice } from '../api/apiSlice';


const airTotalTripsMonthlyAdapter = createEntityAdapter();

const initialState= airTotalTripsMonthlyAdapter.getInitialState();


export const  totalAirTripsMonthlyApiSlice=apiSlice.injectEndpoints({
    endpoints:builder=>({
        getTotalAirTripsMonthly:builder.query({
            query:(arg)=>({
                url:'GetAirTotalTripsMonthly',
                method:'POST',
                body:arg
            }),
            transformResponse:responseData=>{
                return airTotalTripsMonthlyAdapter.setAll(initialState,responseData)
            },
            providesTags:['TotalAirTripsMonthly']
        })
    })

})

export const {useGetTotalAirTripsMonthlyQuery}=totalAirTripsMonthlyApiSlice;

export const selectGetTotalAirTripsMonthlyResult= totalAirTripsMonthlyApiSlice.endpoints.getTotalAirTripsMonthly.select();

const selectGetTotalAirTripsMonthlyData=createSelector(
    selectGetTotalAirTripsMonthlyResult,
    totalAirTripsMonthlyResult=>totalAirTripsMonthlyResult.data
)

export const{
    selectAll:selectTotalAirTripsMonthly,
}=airTotalTripsMonthlyAdapter.getSelectors(state=>selectGetTotalAirTripsMonthlyData(state) ?? initialState)

//used in fron dashboard to show monthly trips bar