
import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';

import {apiIdentitySlice} from '../api/apiIdentitySlice';

const managerAdapter=createEntityAdapter();

const initialState=managerAdapter.getInitialState()

export const managersApiSlice=apiIdentitySlice.injectEndpoints({

//returns a list of clients for specific manager
    endpoints:builder=>({
      
        getclientManagerList:builder.query({
            query:()=>({
                    url:'ClientManagersList'
                }),
            transformResponse:responseData=>{
      
                return managerAdapter.setAll(initialState,responseData)
            },
            providesTags: ['managers']
        })
    })
    })

export const {
    useGetclientManagerListQuery
}=managersApiSlice

export const selectManagerResult=managersApiSlice.endpoints.getclientManagerList.select();

const selectManagerData=createSelector(
    selectManagerResult,
    managerResult=>managerResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring

export const {
    selectAll:selectclientManager
}=managerAdapter.getSelectors(state=>selectManagerData(state)??initialState)
