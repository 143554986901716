
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


import configData from './configURL.json';



const urlBase =configData.IDENTITY;

export const apiIdentitySlice=  createApi({
  
    reducerPath:'apiIdentitySlice',
    baseQuery:fetchBaseQuery(
        {
            baseUrl:urlBase,
            prepareHeaders:(headers)=>{
              
                headers.set('Accept','application/json')
                headers.set('Content-Type', 'application/json')
        
                return headers
                }
        }
        ),
    tagTypes: ['managers'],
    endpoints:(builder)=>({})
})