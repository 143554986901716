import {useEffect,useState} from 'react';
import { useSelector } from 'react-redux/es/exports';
import {useGetAirDestinationsMapQuery} from '../../../features/air/airDestinationsSlice';
import {selectParameters} from '../../../features/reportparameters/parametersSlice';
import AirportMap from './AirportsMap'
import Spinner from '../../../utils/Spinner'

const AirMapResponseiveFiltered =()=>{
    const [mapData , setDataCountry]=useState(null)
    const para=useSelector(selectParameters);
    const {   
        data,
        isLoading,
        isSuccess,
        isError,
        error
      } = useGetAirDestinationsMapQuery(para);

    useEffect(()=>{

        fetch("GeoChart.world.geo.json")
              .then(
                (response)=>response.json())
              .then((data)=> setDataCountry(data)
              )
        },[]
      )


      

      
    let airportsData=[]

      if(isSuccess && data) 
      { 
        airportsData=data['entities']['undefined']
     
      }

    if(isLoading)
      {
          return (<Spinner msg={'Loading Map'}/>)
      }
        else if (isSuccess && airportsData.length >0 &&  (mapData !==null) )
            {


                return (<AirportMap data={airportsData} title={"Airports"} mapdata={mapData}/>)
            }
        else
            {
            return null
            }
  }
  
  export default AirMapResponseiveFiltered


