import Dashb from './Dashb';

const Dashboard=(props)=> {



    return ( 

                <Dashb/>
    
        );
}

export default Dashboard;