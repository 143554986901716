import { useSelector } from 'react-redux';
import { Card,CardHeader,CardContent,Divider,Box } from '@mui/material';
import {selectParameters} from '../../../../../features/reportparameters/parametersSlice';
import {useGetAirTicketTypesQuery} from '../../../../../features/air/airTicketTypesSlice';
import TktTypeTable from './Table';
import Spinner from '../../../../../utils/Spinner';
import Error from '../../../../Error'

const TableAirTicketBdown =()=>{
  const para=useSelector(selectParameters);
  const {   
      data,
      isLoading,
      isSuccess,
      isError,
      error

    } = useGetAirTicketTypesQuery(para);
 
  
    let tableData=[]

    if(isSuccess) 
    {
      tableData=data['entities']['undefined']
    }

    let content;

      if (isLoading) {
            content = <Spinner msg={'Loading'}/>;
        } else if(isSuccess && tableData.length > 0) {
            content =<TktTypeTable data={tableData} err={error}/>
        } else if(isError) {
            content = <Error ErrorMsg={error}/>;
    }
  
  return (
    <Card  sx={{ minWidth: 275,background:'#ebebeb' }}>
      <CardHeader title='Ticket Type' sx={{background:'#999999',  color: 'white'}}  />
      <Divider />
        <CardContent  sx={{background:'#ebebeb', maxHeight: 440 }}>
          <Box
              sx={{
                  height: 400,
                  position: 'relative'
              }}>
            {content}
          </Box>
        </CardContent>
    </Card>
)
}

export default TableAirTicketBdown