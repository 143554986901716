import { useOidcAccessToken, useOidcIdToken, useOidcUser, OidcUserStatus} from "@axa-fr/react-oidc";
import {Alert, Container,Box,Typography} from '@mui/material';

const DisplayUserInfo = () => {

    const{ oidcUser, oidcUserLoadingState } = useOidcUser();

    switch (oidcUserLoadingState){
       
        case OidcUserStatus.Loading:
            return <Alert>User Information are loading</Alert>;
        case OidcUserStatus.Unauthenticated:
            return <Alert>you are not authenticated</Alert>;
        case OidcUserStatus.LoadingError:
            return <Alert>Fail to load user information</Alert>;
        case OidcUserStatus.Loaded:
     
            return (
                    <Container>
                        <Typography variant="h5">User information</Typography>
                        <Typography variant="h6">{oidcUserLoadingState}</Typography>
                        <Typography variant="body1">{JSON.stringify(oidcUser)}</Typography>
                    </Container>
                    );
        default:   return <Alert>just a default</Alert>;
    }
};

const DisplayAccessToken = () => {

    const{ accessToken, accessTokenPayload } = useOidcAccessToken();

    if(!accessToken){
        return <Alert>AccessToken not available</Alert>
    }

    return (
        <Container>
            <Typography variant="h5">Access Token</Typography>
            <Typography variant="h6">Access Token:</Typography> <Typography variant="body1">{JSON.stringify(accessToken)}</Typography>
            {accessTokenPayload != null &&<><Typography variant='h6'>Access Token Payload:</Typography> <Typography variant="body1">{JSON.stringify(accessTokenPayload)}</Typography>
           </>}
        </Container>
    )
};


const DisplayIdToken =() => {
    const{ idToken, idTokenPayload } = useOidcIdToken();

    if(!idToken){
        return <Alert>No Id Token</Alert>
    }

    return (
        <Container>
            <Typography variant="h5">ID Token</Typography>
            <Typography variant="h6">IdToken:</Typography><Typography variant ="body1">{JSON.stringify(idToken)}</Typography>
            {idTokenPayload != null && <><Typography variant='h6'>IdToken Payload:</Typography><Typography variant="body1"> {JSON.stringify(idTokenPayload)}</Typography><Typography variant="body1"> {JSON.stringify(idTokenPayload['auth_time'])}</Typography></>}
        </Container>
    );
}

export const Profile = () => {

    return (
        <Box>
           <DisplayAccessToken/>
           <DisplayIdToken/>
           <DisplayUserInfo/>
        </Box>
    );
}




export default Profile;
