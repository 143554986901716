import { createSlice } from '@reduxjs/toolkit'

const initialState={
    showSpinner:false
}

const spinnerSlice = createSlice({
    name: 'spinner',
    initialState,
    reducers:{
        setSpinner:(state,action)=>{
            state.showSpinner=action.payload
        }
    }
})

export const selectShowSpinner = (state)=> state.spinner.showSpinner
export const {setSpinner}=spinnerSlice.actions
export default spinnerSlice.reducer