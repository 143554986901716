import { createSelector,createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const exchAdapter=createEntityAdapter();

const initialState=exchAdapter.getInitialState()

export const exchRateApiSlice=apiSlice.injectEndpoints({

        
    endpoints:builder=>({

        getExchRate:builder.query({
            query:()=>({
                    url:'GetExchRate',
                    method:'GET'
            }),
            transformResponse:responseData=>{
                let i=1
                let exchRateData={}
                let arrayCtry=['NO','SE','DK']
                    if (responseData['exchrate']){
                        exchRateData['exchrate']=responseData['exchrate'].filter(item => arrayCtry.includes(item.countryCde)).map(item=>{
                        let x=i++
                        return {"id":x,"country":item.countryCde,"exchRate":item.euroExch}
                        })
                    } 
                return  exchAdapter.setAll(initialState,exchRateData)
            },
            providesTags: ['ExchRate']
        })
    })
})

export const {
    useGetExchRateQuery
}=exchRateApiSlice