import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';

import { apiSlice } from '../api/apiSlice';


const countryLevelMapDataAdapter = createEntityAdapter();

const initialState= countryLevelMapDataAdapter.getInitialState();


export const  countryLevelMapDataApiSlice=apiSlice.injectEndpoints({
    endpoints:builder=>({
        getCountryLevelMapData:builder.query({
            query:(arg)=>({
                url:'GetCountryLevelMapData',
                method:'POST',
                body:arg
            }),
            transformResponse:responseData=>{

            let i=1
            let mapCountryLevelData={}
            if (responseData['dsCountryLevelMapData']){
                mapCountryLevelData['dsCountryLevelMapData']=responseData['dsCountryLevelMapData'].filter(ctry=>(ctry.trips >0)).map(ctry=>{
                    //let avg= Math.floor(parseInt(dest.spend)/parseInt(dest.trips));
                    //nothing worked unless I added this generated id field.. wtf!
                    let x=i++
                    return {"id":x,"name":ctry.name,"coordinates":[ctry.longitude,ctry.latitude],"airtrips":ctry.trips,"airspend":ctry.tripspend,"hotelnights":ctry.hotelnights,"hotelspend":ctry.hotelspend}
                })
            }
            
                return countryLevelMapDataAdapter.setAll(initialState,mapCountryLevelData)
            },
            providesTags:['CountryLevelData']
        })
    })

})

export const {useGetCountryLevelMapDataQuery}=countryLevelMapDataApiSlice;

export const selectCountryLevelMapResult= countryLevelMapDataApiSlice.endpoints.getCountryLevelMapData.select();

const selectCountryLevelMapData=createSelector(
    selectCountryLevelMapResult,
    countryLevelMapDataResult=>countryLevelMapDataResult.data
)

export const{
    selectAll:selectCountryLevelMap,
}=countryLevelMapDataAdapter.getSelectors(state=>selectCountryLevelMapData(state) ?? initialState)

//used in front dashboard Map