import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function Spinner(props) {

  return (
     
    <Box position="relative" display="inline-flex">
      <CircularProgress color="secondary"/>
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{props.msg}</Typography>
      </Box>
    </Box>
  );
}

export default Spinner;