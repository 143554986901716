import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';

import { apiSlice } from '../api/apiSlice';


const airTotalTripsMonthlyTrendAdapter = createEntityAdapter();

const initialState= airTotalTripsMonthlyTrendAdapter.getInitialState();


export const  totalAirTripsMonthlyTrendApiSlice=apiSlice.injectEndpoints({
    endpoints:builder=>({
        getTotalAirTripsMonthlyTrend:builder.query({
            query:(arg)=>({
                url:'GetAirTotalTripsAreaMonthlyTrend',
                method:'POST',
                body: arg
            }),
            transformResponse:responseData=>{
  
                return airTotalTripsMonthlyTrendAdapter.setAll(initialState,responseData)
            },
            providesTags:['dsTotalTripsMonthlyTrend']
        })
    })

})

export const {useGetTotalAirTripsMonthlyTrendQuery}=totalAirTripsMonthlyTrendApiSlice;

export const selectGetTotalAirTripsMonthlyTrendResult= totalAirTripsMonthlyTrendApiSlice.endpoints.getTotalAirTripsMonthlyTrend.select();

const selectGetTotalAirTripsMonthlyTrendData=createSelector(
    selectGetTotalAirTripsMonthlyTrendResult,
    totalAirTripsMonthlyTrendResult=>totalAirTripsMonthlyTrendResult.data
)

export const{
    selectAll:selectTotalAirTripsMonthlyTrend,
}=airTotalTripsMonthlyTrendAdapter.getSelectors(state=>selectGetTotalAirTripsMonthlyTrendData(state) ?? initialState)