import React from 'react';
import { useSelector } from 'react-redux';
import {selectParameters} from './../../../../features/reportparameters/parametersSlice';
import {selectHotelChainTop10,useGetHotelChainsTop10Query} from './../../../../features/hotel/hotelChainsTop10Slice'

import Doughnuts  from './../../Doughnuts';
import Error from '../../../Error';

const Top10ChainsDoughnut=(props)=> {
  const para=useSelector(selectParameters);
    
      const { 
        data,  
        isLoading,
        isSuccess,
        isError,
        error
      } = useGetHotelChainsTop10Query(para);

      let dataState=[]

      if(isSuccess) 
      { 
         dataState=data['entities']['undefined']
      }
      let chartData=[];
      let chartLabels=[];
      let devices=[];

      if(isSuccess && dataState) 
      { 
        chartData=dataState.map(d=>(d.nrNights))
        chartLabels=dataState.map(d=>(d.hotelChains ));
        devices=dataState.map(d=>({"title":d.hotel,"value":d.nrNights,"color":'red'}));
      }
    

      if(isLoading)
        {return (<p>...Loading</p>)}
      else if (isSuccess && dataState) 
        {
          return (
            <Doughnuts title={'Hotels Top Chains'} chartData={chartData} labeldata={chartLabels} devices={null}/>
          )
        }
      else
        {
          return(<Error ErrorMsg={error}/>);
        }
  }

export default Top10ChainsDoughnut;