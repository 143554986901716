
import {selectParameters} from '../../features/reportparameters/parametersSlice';
import {useSelector} from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import  Grid  from '@mui/material/Grid';
import { useGetExchRateQuery } from '../../features/reportparameters/exchRateSlice';
import {useOidcAccessToken} from '@axa-fr/react-oidc';


  const AppBarMis=()=>{

    const {accessToken, accessTokenPayload} = useOidcAccessToken();
    const para=useSelector(selectParameters);

    const {   
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetExchRateQuery()

    let exchRate=[];
    
    if(isSuccess) 
    { 
        exchRate=data['entities']['undefined']
    }

    let arrayExchElement=[];
    let exchElement={}
    if (isSuccess && exchRate){
      let i=1
        exchRate.forEach(ex =>{ 
            i++
            exchElement=  <Grid item key={i}><Typography  variant="body2">{ex.country}:{ex.exchRate}</Typography></Grid>
            arrayExchElement.push(exchElement)
        })
    }

    return(
        <AppBar position="fixed" elevation={0} sx={{  backgroundColor: '#999999'}}>
        <Toolbar variant="dense">
        <Grid container spacing={2} direction="row" sx={{marginLeft:30}} >
                        
                {Object.keys(para).length > 0 && <> <Grid item><Typography  variant="body2">CorpCode:{para.corpcode}</Typography></Grid> 
                    <Grid item><Typography  variant="body2">Country:{para.country}</Typography></Grid>
                    <Grid item><Typography  variant="body2">Division:{para.divcode}</Typography></Grid>
                    <Grid item><Typography  variant="body2">Client Nr:{para.clientnr}</Typography></Grid>
                    <Grid item><Typography  variant="body2">From:{para.fromdate}</Typography></Grid>
                    <Grid item><Typography  variant="body2">To:{para.todate}</Typography></Grid>
                    <Grid item><Typography  variant="body2">  Euro exch rates:</Typography></Grid>
                    {arrayExchElement}
                 </>}
                    
                </Grid>
                <Grid container spacing={2}  direction="row" justifyContent="right" alignItems="right"  sx={{marginLeft:2}} >
                    {accessToken && <>
                    <Grid item><Typography  variant="body2">Name:{accessTokenPayload.first_name + ' '+ accessTokenPayload.last_name}</Typography></Grid>
                    <Grid item><Typography  variant="body2">Role:{accessTokenPayload.role}</Typography></Grid>
                    <Grid item><Typography  variant="body2">Corporation:{accessTokenPayload.corpdefault}</Typography></Grid></>}
                </Grid>
            </Toolbar>
            </AppBar>
    )
}
 
export default AppBarMis