import {useRef,useEffect,useState} from 'react';
import { geoMercator, geoPath,  select , min, max, scaleLinear} from 'd3';
import useResizeObserver from "../../useResizeObserver";

import Card  from '@mui/material/Card'; 
import CardHeader  from '@mui/material/CardHeader'; 
import CardContent  from '@mui/material/CardContent'; 
import Divider  from '@mui/material/Divider'; 
import Paper  from '@mui/material/Paper'; 
import Typography  from '@mui/material/Typography'; 

import Dialog from '@mui/material/Dialog';

import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


import './../styles.css';


const HotelMapResponsive = (props) => {

  const property="pop_est"
  const svgRef = useRef();
  const wrapperRef = useRef();
  const dimensions = useResizeObserver(wrapperRef);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [openDialog,setOpenDialog]=useState(false);


  const cities = props.data;

  const handleMarkerClick = i => {
    let inD=i-1

    setSelectedCity(cities[inD]);
    setOpenDialog(true);
  }

  const closeDialog =()=>{
    setOpenDialog(false);
    }

/*   useEffect(()=>{
    fetch("./GeoChart.world.geo.json")
          .then(
            (response)=>response.json())
          .then((data)=> setDataCountry(data)
          )
    },[]
  )
 */
  // will be called initially and on every data change ATTN! The whole map is drawn in useEffect!!
  useEffect(() => {

    const svg = select(svgRef.current);
  
    const minProp = min(props.mapdata.features, feature => feature.properties[property]);
    const maxProp = max(props.mapdata.features, feature => feature.properties[property]);
    const colorScale = scaleLinear()
      .domain([minProp, maxProp])
      .range(['#02175A','#00408A','#0069AD','#0093BF','#00BBC0','#00E3B5']);

    // use resized dimensions
    // but fall back to getBoundingClientRect, if no dimensions yet.

    const { width, height } =
      dimensions || wrapperRef.current.getBoundingClientRect();
    svg.height=height;
      svg.width=width; 
   
    
    const projection = geoMercator()
      .fitSize([svg.width, svg.height], selectedCountry || props.mapdata)
      .precision(150);

    // takes geojson data,
    // transforms that into the d attribute of a path element
    const pathGenerator = geoPath().projection(projection);
      //geoPath().projection(projection);

/*     const zoomed=(event)=> {
      projection.translate(event.translate).scale(event.scale);
      svg.selectAll("country").attr("d", pathGenerator);
      
      svg.selectAll("circle")
      .attr("cx", function (d) { return projection(d.coordinates)[0]; })
      .attr("cy", function (d) { return projection(d.coordinates)[1]; })
    } 
*/

/*     svg.call(zoom().on("zoom",(event)=>{

      svg.selectAll(".country,.circle").attr('transform', event.transform)
    })) 
*/



  // render each country
    svg
      .selectAll(".country")
      .data(props.mapdata.features)
      .join("path")
      .on("click", (event, feature) => {
        setSelectedCountry(selectedCountry === feature ? null : feature);
      })
      //.on("zoom",(event, feature) => {zoomed(event)})
      .attr("class", "country")
      .transition()
      .attr("fill", feature => colorScale(feature.properties[property]))
      .attr("d", feature => pathGenerator(feature))
      ;

    //render cities
    svg.selectAll("circle").remove() // this is needed otherwise the circles get applied multiple times 
    svg.selectAll("circle")
      .data(cities)
      .enter()
      .append("circle")
      .attr("cx", function (d) { return projection(d.coordinates)[0]; })
      .attr("cy", function (d) { return projection(d.coordinates)[1]; })
      .attr("class","markers")
      .attr("r", 3)
      .attr("fill", "#ffffff")
      .attr("stroke","#ffffff")
      .on("click",(event,data)=>{
          handleMarkerClick(data.id);
      })
    // render text
 
    
   /*  svg
      .selectAll(".maplabel")
      .data([selectedCity])
      .join("text")
      .attr("class", "maplabel")
      .attr("x", 0)
      .attr("y", 15)
      .text(
        selectedCity =>
        selectedCity &&
        selectedCity.name +
            ": " +
            "Spend:" + selectedCity.spend.toLocaleString() + '  ' +
            "Average:" +  selectedCity.avg.toLocaleString()
      )  */
    
    

  }, [props.data, dimensions, property,selectedCity,selectedCountry]);

  const DisplayCard =(props)=>{

    return (
        <Paper elevation={0}>
              <Typography   variant='body1'> Hotel Nights: {props.city.nights.toLocaleString()} </Typography>
              <Typography   variant='body1'> Hotel Spend: {props.city.spend.toLocaleString()} </Typography>
         
        </Paper>
    )
  }

  return (
    <>
        <Card sx={{height:"100%"}}>
            <CardHeader title={props.title}/>
            <Divider />
            <CardContent 
                sx={{ 
                  height:"100%",
                  padding: 5,
                  "&:last-child": {
                    paddingBottom: 10
                  }}}  ref={wrapperRef}>   
            <svg ref={svgRef}></svg>
                <Dialog
                    open={openDialog}
                    onClose={closeDialog}>
                          <DialogTitle sx={{fontSize:'1rem'}}>{selectedCity && selectedCity.name }</DialogTitle>
                          <DialogContent>
                            <DisplayCard city={selectedCity}/>
                          </DialogContent>
                </Dialog>    
                      
            </CardContent>
        </Card>
    </>
  );
}

export default HotelMapResponsive
