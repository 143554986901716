
import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';
import {apiSlice} from '../api/apiSlice';

//api/MiswebApi/Misweb/

const hotelTop5HotelsAdapter=createEntityAdapter();

const initialState=hotelTop5HotelsAdapter.getInitialState()

export const hotelPropertiesTop5ApiSlice=apiSlice.injectEndpoints({

    endpoints:builder=>({
        getHotelPropertiesTop5:builder.query({
            query:(arg)=>({
                    url:'GetTop5Hotels',
                    method:'POST',
                    body:arg
                }),
            transformResponse:responseData=>{

                return hotelTop5HotelsAdapter.setAll(initialState,responseData)
            },
            providesTags: ['Top5Hotels']
        })
    })
})

export const {
    useGetHotelPropertiesTop5Query,
}=hotelPropertiesTop5ApiSlice

export const selectHotelPropertiesTop5Result=hotelPropertiesTop5ApiSlice.endpoints.getHotelPropertiesTop5.select();

const selectHotelPropertiesTop5Data=createSelector(
    selectHotelPropertiesTop5Result,
   hotelPropertiesTop5Result=>hotelPropertiesTop5Result.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring

export const {
    selectAll:selectHotelPropertiesTop5
}=hotelTop5HotelsAdapter.getSelectors(state=>selectHotelPropertiesTop5Data(state)??initialState)