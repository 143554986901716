
import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';

import {apiSlice} from '../api/apiSlice';



const airAdvanceAdapter=createEntityAdapter();

const initialState=airAdvanceAdapter.getInitialState();

export const advDaysApiSlice=apiSlice.injectEndpoints({
    
    endpoints:builder=>({
        getAdvDays:builder.query({
            query:(arg)=>({
                    url:'GetAdvancedDays',
                    method:'POST',
                    body:arg
                }),
            transformResponse: responseData => {

                return airAdvanceAdapter.setAll(initialState,responseData)
                } ,
                providesTags: ['AdvanceDays']
                
    })})
})
//api/MiswebApi/Misweb/

export const {useGetAdvDaysQuery} = advDaysApiSlice

// returns the query result object
export const selectGetAdvDaysResult = advDaysApiSlice.endpoints.getAdvDays.select()

// Creates memoized selector
const selectGetAdvDaysData = createSelector(
    selectGetAdvDaysResult,
    advDaysResult => advDaysResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAdvDays,
  
} = airAdvanceAdapter.getSelectors(state => selectGetAdvDaysData(state) ?? initialState)
