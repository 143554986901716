import { useSelector } from 'react-redux';
import { Card,CardHeader,CardContent,Divider,Box } from '@mui/material';
import {selectParameters} from './../../../../features/reportparameters/parametersSlice';
import {useGetAirlinesQuery} from './../../../../features/air/airAirlinesSlice';

import Spinner from './../../../../utils/Spinner';
import Error from '../../../Error';
import AirByAirlineTable from './Table'

const TableAirByAirline =(props)=>{
  const para=useSelector(selectParameters);
  const {   
      data,
      isLoading,
      isSuccess,
      isError,
      error

    } = useGetAirlinesQuery(para);
 

    let tableData={}

    if(isSuccess) 
    { 

      tableData=data['entities']['undefined']
    }

    let content;

      if (isLoading) {
            content = <Spinner msg={'Loading'}/>;
        } else if (isSuccess && tableData) {
            content =<AirByAirlineTable data={tableData}/>
        } else if (isError) {
          content = <Error ErrorMsg={error}/>;
    }
       
    return (
      <Card>
        <CardHeader title='Airlines' />
        <Divider />
          <CardContent>
            <Box
                sx={{
                    height: 445,
                    position: 'relative'
                }}>
              {content}
            </Box>
          </CardContent>
      </Card>
  )
  }
 
  export default TableAirByAirline