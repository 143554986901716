import Box from '@mui/material/Box';
import Spinner from './../../utils/AuthSpinner';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';

export const Authenticate =(props)=>{
    return(
        <Container>
            <Box component="container" maxWidth="xs" sx={{ width: '40%' , alignItems: 'center',justifyContent:'center',marginTop:400,marginLeft:400 }} >
                <Spinner msg={props.msg}/>
            </Box>
            <Box>
            {props.sessionLost && <Link to={'/Login'}>Log In</Link>}
            </Box>
        </Container>
        )

}

export default Authenticate