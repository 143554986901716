import  { Fragment } from 'react';

import {Button,Grid, List, ListItem, ListItemText} from '@mui/material';


const Error =(props)=>{
    let tmpArr=[];
    let errArr=[];

    if(Array.isArray(props.ErrorMsg))
    {tmpArr=props.ErrorMsg;

    tmpArr.forEach((err) =>{
      errArr.push(<li>{err}</li>);
    })
}
   
    else{

     if( props.ErrorMsg!==null && typeof(props.ErrorMsg) !=='undefined')
     { 
      Object.keys(props.ErrorMsg)[0]!=="0"?Object.values(props.ErrorMsg).forEach((value,index)=>errArr.push(<ListItem key={index}><ListItemText primary={value !==null && typeof(value)!=='undefined'?value:'No detail'}/></ListItem>)):errArr=[]
    }
    }


  
      { if( props.ErrorMsg!==null && typeof(props.ErrorMsg) !=='undefined'){

        return(
        <Grid>
        <Grid item xs zeroMinWidth>
              
             <List>
                  {errArr}
             </List>
          
     
                  <Button  content="Ok" primary size='mini'/>
                  </Grid>

        </Grid>
        )
      }
        else{return(null)}
      
      }
    
  }

export default Error;
