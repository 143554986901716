
import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';
import {apiSlice} from '../api/apiSlice';

//api/MiswebApi/Misweb

const airCO2Adapter=createEntityAdapter();

const initialState=airCO2Adapter.getInitialState()

export const airCO2ApiSlice=apiSlice.injectEndpoints({

    endpoints:builder=>({
        getAirCO2:builder.query({
            query:(arg)=>({
                    url:'GetAirCo2',
                    method:'POST',
                    body:arg
                }),
            transformResponse:responseData=>{
      
                return airCO2Adapter.setAll(initialState,responseData)
            },
            providesTags: ['AirCO2']
        }),
        getAirCO2All:builder.query({
            query:(arg)=>({
                    url:'GetAirCo2All',
                    method:'POST',
                    body:arg
                }),
            transformResponse:responseData=>{
      
                return airCO2Adapter.setAll(initialState,responseData)
            },
            providesTags: ['AirCO2All']
        })
    })
    })



export const {
    useGetAirCO2Query,useGetAirCO2AllQuery
}=airCO2ApiSlice

export const selectCO2Result=airCO2ApiSlice.endpoints.getAirCO2.select();

const selectCO2Data=createSelector(
    selectCO2Result,
    airCO2Result=>airCO2Result.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring

export const {
    selectAll:selectCO2
}=airCO2Adapter.getSelectors(state=>selectCO2Data(state)??initialState)