import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DataToExcel from '../../../../utils/DataToExcel';

import { styled } from '@mui/material';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const AirAllEmissionsTable=(props)=>{

    let data=props.data;

    return (
  
      <TableContainer sx={{ maxHeight: 440 }}>
            <TableRow>
              <DataToExcel dataForExcel={data} fileName={"CO2"}/>
            </TableRow>
        <Table  size="small" stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                    <TableCell align="left" width="40%">Area</TableCell>
                    <TableCell align="right" width="10%">Segments </TableCell>
                    <TableCell align="right" width="10%">KM </TableCell>
                    <TableCell align="right" width="10%">C02 </TableCell>
                    <TableCell align="right" width="10%">C02e </TableCell>
                    <TableCell align="right" width="10%">CH4 </TableCell>
                    <TableCell align="right" width="10%">N2O </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row,index) => (
               <StyledTableRow key={index}>
                  <TableCell align="left">{row.area}</TableCell>
                  <TableCell align="right">{row.totalSegments}</TableCell>
                  <TableCell align="right">{row.totalKM}</TableCell>
                  <TableCell align="right">{row.totalC02}</TableCell>
                  <TableCell align="right">{row.totalC02e}</TableCell>
                  <TableCell align="right">{row.totalCH4}</TableCell>
                  <TableCell align="right">{row.totalN20}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          </TableContainer>
    );
  }


export default AirAllEmissionsTable