import { useSelector } from 'react-redux';
import { Card,CardHeader,CardContent,Divider,Box } from '@mui/material';
import {selectParameters} from './../../../../features/reportparameters/parametersSlice';
import {useGetHotelChainsQuery} from './../../../../features/hotel/hotelChainsSlice';
import HotelChainsTable from './Table';
import Spinner from './../../../../utils/Spinner';

const TableHotelChains =(props)=>{
  const para=useSelector(selectParameters);
  const {   
      data,
      isLoading,
      isSuccess,
      isError,
      error

    } = useGetHotelChainsQuery(para);
 

    let tableData={}

    if(isSuccess) 
    { 

      tableData=data['entities']['undefined']
    }

    let content;

      if (isLoading) {
            content = <Spinner msg={'Loading'}/>;
        } else if (isSuccess && tableData) {
            content =<HotelChainsTable data={tableData}/>
        } else if (isError) {
            content = <p>{error}</p>;
    }
  
    return (
      <Card>
        <CardHeader title='Hotel Chains' />
        <Divider />
          <CardContent>
            <Box
                sx={{
                    height: 445,
                    position: 'relative'
                }}>
              {content}
            </Box>
          </CardContent>
      </Card>
  )
}

export default (TableHotelChains)
