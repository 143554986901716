import {Fragment} from 'react';
import {Helmet} from "react-helmet";

import CssBaseline from '@mui/material/CssBaseline';
import Sidebar from './components/Navigation/SideBar/';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import {ThemeProvider,Container,Alert,Box} from '@mui/material';
import {mistheme} from './theme/mistheme';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import HomePage from './pages/Home';
import Admin from './pages/Admin';
import Dashboard from './pages/Dashboard/';
import Air from './pages/Air/Air';
import Hotel from './pages/Hotel/Hotel';
import Login from './features/auth/Login';

import SilentLogOut from './features/auth/SilentLogOut';
import Authenticate from './features/auth/Authenticate';


import { OidcProvider,OidcSecure } from '@axa-fr/react-oidc';

import AppBarMis from './components/Navigation/AppBarMis';
import {configurationIdentityServer} from './configurations';

import DashboardLayout from './layout/dashboard-layout';

import Profile from './pages/Test/profile';

import {TimeoutLogic} from "./utils/TimeoutLogic";
import {selectParameters} from './features/reportparameters/parametersSlice';
import {useSelector} from 'react-redux';


const anonce='nonce-' + uuidv4()
const cache = createCache({
  key: 'mon',
  nonce: anonce,
  prepend: false,
});
//console.log(anonce)


const  App=()=> {
  const para=useSelector(selectParameters);
  const AuthenticatingError = () => <Container maxWidth={false} ><Alert>An Error has happened. Please try again.</Alert></Container>
  const Authenticating = () =><Authenticate msg={'Authenticating... just a moment.'}/>     //<Container maxWidth={false} ><Alert>Authenticating... just a moment.</Alert></Container>
  const Loading = () => <Authenticate msg={'Loading data... just a moment.'}/>  
  const CallBackSuccess = () =><Authenticate msg={'You have been authenticated. Please wait while your access is arranged.'}/>  //<Container maxWidth={false} ><Alert>You have been authenticated. Please wait while we arrange your access.</Alert></Container>
  const SessionLost  = () =><Authenticate msg={'Your session has ended. Please log in to continue.'} sessionLost={true}/>  //<Container maxWidth={false} ><Alert>Your session has ended. Please log in to continue.</Alert></Container>
  const NoParameter = () =><Container maxWidth={false} ><Alert>Some or all of the required parameters are empty. To continue, please check you have filled in dates correctly.</Alert></Container>


  return (
    <Fragment>
   
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="http-equiv" content="'Content-Security-Policy' 'content'='default-src 'self';script-src 'self'; style-src 'self' ${anonce};  worker-src 'self' blob:"/>
        <title>MisWeb</title>
      </Helmet>

   {/* this is where you plug in all customization for material css components */}
   <CacheProvider value={cache}>
      <ThemeProvider theme={mistheme}>  
      <CssBaseline />

      <OidcProvider 
                configuration={configurationIdentityServer}  
                loadingComponent={Loading}
                authenticatingComponent={Authenticating}  
                sessionLostComponent={SessionLost}
                authenticatingErrorComponent={AuthenticatingError}
                callbackSuccessComponent={CallBackSuccess}
                >
     
      <BrowserRouter>

    

      <AppBarMis/>
  
        <Box sx={{ display: 'flex' }}>
        
          <DashboardLayout>
            <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
            <TimeoutLogic/>
            <Sidebar/>
            <Routes>
                <Route path  ='/' element={<Login/>}/>
                <Route path  ='/login' element={<Login/>}/>
                <Route path='/home' element={<HomePage/>}/>
  
                <Route path='/dashboard' element={<OidcSecure>{para.hasOwnProperty('fromdate')?<Dashboard/>:<NoParameter/>}</OidcSecure>}/>
              
                <Route path="/air" element={<OidcSecure>{para.hasOwnProperty('fromdate')?<Air/>:<NoParameter/>}</OidcSecure>}/>
              
                <Route path="/hotel" element={<OidcSecure>{para.hasOwnProperty('fromdate')?<Hotel/>:<NoParameter/>}</OidcSecure>}/>
                <Route path="/noparameter" element={<NoParameter/>}/>
                <Route path="/admin" element={<Admin/>}/> 
              

      {/*           <Route path='/authentication/callback' element={<Callback/>}/>
                <Route path='/authentication/silent-login' element={<SilentLogin/>}/>
                <Route path='/authentication/silent-callback' element={<SilentCallback/>}/>  */}
  
                <Route path="/loggedout" element={<SilentLogOut/>} />
                <Route path="/profile" element={<Profile/>} />
            </Routes>
           
            </Box>  
          </DashboardLayout>
          </Box>
     
          </BrowserRouter>
     
          </OidcProvider>
        </ThemeProvider>
        </CacheProvider>

    </Fragment>
  );
}

export default App