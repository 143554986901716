import { createSlice } from '@reduxjs/toolkit'

const initialState={
    roles:[]
}

const rolesSlice = createSlice({
    name: 'roles',
    initialState,
    reducers:{
        setRoles:(state,action)=>{
           if(action.payload){
            state.roles=action.payload
        }
        }
    }
})



export const selectRoles = (state)=> state.userRoles.roles
export const {setRoles}=rolesSlice.actions
export default rolesSlice.reducer