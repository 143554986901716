import {useState,useEffect} from 'react';
import { useDispatch } from "react-redux";


import {setParameters} from '../../features/reportparameters/parametersSlice';
import {useGetCustNrQuery} from '../../features/reportparameters/custNrListSlice';
import {useGetDivNrQuery} from '../../features/reportparameters/divNrListSlice';
import {useGetmanagersClientsQuery} from '../../features/reportparameters/managersClientsApiSlice';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem"

import {useOidcAccessToken,useOidc} from '@axa-fr/react-oidc';

export const ParameterInputs=()=>{
  
    const [role,setRole]=useState()
    const [email,setEmail]=useState({})
    const [custSkip, setCustSkip] = useState(true)
    const [divSkip, setDivSkip] = useState(true) 
    const [clientStructureParameters,setClientStructureParameters]=useState({})
    const [reportParameters,setReportParameters]=useState({});


    const dispatch=useDispatch();
    const { isAuthenticated} = useOidc();
    const { accessTokenPayload} = useOidcAccessToken();

    const { data: divs = [],isError:inError, isFetching: divFetching ,isSuccess:divSuccess}= useGetDivNrQuery(clientStructureParameters,{skip:divSkip,});
    const { data: custnrs = [],isError:inErrorCust,  isFetching: custFetching ,isSuccess:custSuccess}=useGetCustNrQuery(clientStructureParameters,{skip:custSkip,}); 
    const { data: clientManagerClients = [],isError:inErrorClientManager,  isFetching: clientManagerFetching ,isSuccess:clientManagerSuccess}=useGetmanagersClientsQuery(email,{skip:custSkip,}); 

useEffect(()=>{
    if (accessTokenPayload){
        var corpc=accessTokenPayload.corpdefault;
        var userrole=accessTokenPayload.role;
        var useremail=accessTokenPayload.email;
        var name='corpcode'
        setClientStructureParameters({...clientStructureParameters,[name]:corpc});
        setReportParameters({...reportParameters,[name]:corpc});
        setRole(userrole);
        setEmail({...email,['email']:useremail});
        setDivSkip(false);
        setCustSkip(false);
    }
},[accessTokenPayload.corpdefault])

const optionsCtry = [
    { key: '1', text: 'Norway',flag:'no', value: 'NO' },
    { key: '2', text: 'Sweden',flag:'se', value: 'SE' },
    { key: '3', text: 'Finland',flag:'fi', value: 'FI' },
    { key: '4', text: 'Denmark',flag:'dk', value: 'DK' },
    { key: '5', text: '',flag:'', value: null }
  ]

  let selectOptionsManagerClients;

  if(role==='Admin' || 'clientmanager'){
  
    if(clientManagerSuccess && clientManagerClients)
    {
        let managerClients = Object.assign([], clientManagerClients['entities']['undefined']);
        const topObj={
            "corpCode":null,
            "corpname": "clear"
            }
            managerClients.push(topObj)
            selectOptionsManagerClients=managerClients.map((cli,index)=>{
            return( <MenuItem key={index} value={cli.corpCode}>{cli.corpname}</MenuItem>)
        })
    }
  }


    let selectOptionsCtry
    selectOptionsCtry=optionsCtry.map((ctry)=>{
        return( <MenuItem key={ctry.key} value={ctry.value}>{ctry.text}</MenuItem>)
    })

    let  selectOptionsCust
    let  selectOptionsDiv
            if(custSuccess )
            {
                let fullCustNrs = Object.assign([], custnrs['entities']['undefined']);
                const topObj={
                    "custnr":null,
                    "customer_name": "clear"
                    }
                    fullCustNrs.push(topObj)
                    selectOptionsCust=fullCustNrs.map((nr,index)=>{
                    return( <MenuItem key={index} value={nr.custnr}>{nr.customer_name}</MenuItem>)
                })
            }

            if(divSuccess)
                { 

                let fullDivNrs = Object.assign([], divs['entities']['undefined']);
                    const topObjDiv={
                        "division_code":null,
                        "division_name":"clear"
                        }
                        fullDivNrs.push(topObjDiv)
                        selectOptionsDiv=fullDivNrs.map((nr,index)=>{
                        return( <MenuItem key={index} value={nr.division_code}>{nr.division_name}</MenuItem>)
                    })
                }


    const handleOnChange=(event)=>{
        const { name, value } = event.target;
        if(name==='corpcode'){
            setClientStructureParameters({...clientStructureParameters,[name]:value});
            setReportParameters({...reportParameters,[name]:value})
        }   
        if(name==='divcode'){
            setClientStructureParameters({...clientStructureParameters,[name]:value});
            setReportParameters({...reportParameters,[name]:value})
        }
        else{
            setReportParameters({...reportParameters,[name]:value})
        }
    }

    const handleOnBlur=(event)=>{
        const { name, value } = event.target;
        (name==='corpcode' || name==='divcode') && setClientStructureParameters({...clientStructureParameters,[name]:value})
        setReportParameters({...reportParameters,[name]:value})
    }

    const saveParameters=()=>{

        let okToSave=validateSave();
            if(okToSave)
            {
         
            dispatch(setParameters(reportParameters))
            }
            else 
            {
                alert('Parameters not saved. To continue, please check you have provided dates and that they are in the correct format.')
            }
        }

    const validateSave=()=>{
        let isValid=false;
        if(reportParameters.hasOwnProperty('fromdate') && reportParameters.hasOwnProperty('todate')){
    
            if(reportParameters['fromdate'].length===6 && reportParameters['todate'].length===6)
            isValid=true;
        }
        else{
            isValid=false;
        }

        return isValid;
    }

    var corptextbox


    if(role==='Admin' || role==='clientmanager'){
        corptextbox=<TextField
        id="corpcode"
        name="corpcode"
        select
        defaultValue={reportParameters.corpcode}
        label="Clients"
        fullWidth
        autoComplete='off'
        size="small"
        variant="outlined"
        onChange={handleOnChange}
       /*  onBlur={handleOnBlur} */
        InputLabelProps={{style : {color : 'white'} }}
        >
        {selectOptionsManagerClients}
  
        </TextField>
    }
    else{
        corptextbox=        <TextField
        id="corpcode"
        name="corpcode"
        label="Corp Code"
        autoComplete='off'
        value={ reportParameters.corpcode}
        required
        fullWidth
        size="small"
        variant="outlined"
        onBlur={handleOnBlur}
        InputLabelProps={{style : {color : 'white'} }}
    />
    }

    return(
        <>
            <Typography variant='h6' >
            Parameters
            </Typography>
         
                <Grid container
                spacing={3}
                maxHeight={400}     
                sx={{paddingTop:4}}>          
                        <Grid item xs={10} sm={10} lg={10} md={10} xl={10}>
                           {corptextbox}
                        </Grid>
                        <Grid item xs={10} sm={10} lg={10} md={10} xl={10}>
                            <TextField
                                id="country"
                                name="country"
                                label="Country"
                                select
                                fullWidth
                                size="small"
                                autoComplete='off'
                                variant="outlined"
                                onChange={handleOnChange}
                                InputLabelProps={{style : {color : 'white'} }}
                            >{selectOptionsCtry}
                            </TextField>
                        </Grid>
                        <Grid item xs={10} sm={10} lg={10} md={10} xl={10}>
                            <TextField
                                id="divcode"
                                name="divcode"
                                select
                                defaultValue={""}
                                label="Division"
                                fullWidth
                                autoComplete='off'
                                size="small"
                                variant="outlined"
                                onChange={handleOnChange}
                               /*  onBlur={handleOnBlur} */
                                InputLabelProps={{style : {color : 'white'} }}
                                >
                                {selectOptionsDiv}
                          
                                </TextField>
                        </Grid>
                     
                        <Grid item xs={10} sm={10} lg={10} md={10} xl={10}>
                        <TextField
                                id="clientnr"
                                name="clientnr"
                                select
                                defaultValue={""}
                                label="Client Nr"
                                autoComplete='off'
                                fullWidth
                                size="small"
                                variant="outlined"
                                InputLabelProps={{style : {color : 'white'} }}
                                onChange={handleOnChange}
                                >
                                {selectOptionsCust}
                          
                                </TextField>
                         </Grid> 
                         <Grid item xs={10} sm={10} lg={10} md={10} xl={10}>
                            <TextField
                                id="fromdate"
                                name="fromdate"
                                helperText="YYYYMM"
                                autoComplete='off'
                                required
                                label="From Date"
                                fullWidth
                                size="small"
                                variant="outlined"
                                onBlur={handleOnBlur}
                                InputLabelProps={{style : {color : 'white'} }}
                             />
                         </Grid>
                         <Grid item xs={10} sm={10} lg={10} md={10} xl={10}>
                            <TextField
                                id="todate"
                                name="todate"
                                helperText="YYYYMM"
                                autoComplete='off'
                                required
                                label="To Date"
                                fullWidth
                                size="small"
                                variant="outlined"
                                onBlur={handleOnBlur}
                                InputLabelProps={{style : {color : 'white'} }}
                            /> 
                         </Grid>  
                         <Grid item xs={10} sm={10} lg={10} md={10} xl={10}>
                        <Button
                        color="alan"
                            variant="contained"
                            disableElevation
                            onClick={saveParameters}
                            size='small'
                            sx={{ mt: 1,backgroundColor:''}}>
                            Save
                        </Button>
     
                        </Grid>  

            </Grid>
        </>
   
    )
}