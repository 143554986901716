import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';
import {apiSlice} from '../api/apiSlice';

//api/MiswebApi/Misweb/

const hotelMapDestinationsAdapter=createEntityAdapter();

const initialState=hotelMapDestinationsAdapter.getInitialState()

export const hotelMapDestinationsApiSlice=apiSlice.injectEndpoints({

    endpoints:builder=>({
        getHotelMapDestinations:builder.query({
            query:(arg)=>({
                    url:'GetHotelMapDestinations',
                    method:'POST',
                    body:arg
                }),
                transformResponse:responseData=>{
        
                let i=1
                let hotelDest={}
                if (responseData['dsHotelMapDest']){
                    hotelDest['dsHotelMapDest']=responseData['dsHotelMapDest'].filter(dest=>(dest.nights >5)).map(dest=>{
                        let avg= Math.floor(parseInt(dest.spend)/parseInt(dest.nights));
                        //nothing worked unless I added this generated id field.. wtf!
                        let x=i++
                        return {"id":x,"name":dest.destCity,"coordinates":[dest.longDec,dest.latDec],"nights":dest.nights,"spend":dest.spend,avg:avg}
                    })
                }
                
                    return hotelMapDestinationsAdapter.setAll(initialState,hotelDest)
                },
            providesTags: ['HotelMapDestinations']
        })
    })
})

export const {
    useGetHotelMapDestinationsQuery
}=hotelMapDestinationsApiSlice

export const selectHotelMapDestinationsResult=hotelMapDestinationsApiSlice.endpoints.getHotelMapDestinations.select();

const selectHotelChainsData=createSelector(
    selectHotelMapDestinationsResult,
    hotelMapDestinationsResult=>hotelMapDestinationsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring

export const {
    selectAll:selectHotelMapDestinations

}=hotelMapDestinationsAdapter.getSelectors(state=>selectHotelMapDestinationsResult(state)??initialState)