import { useSelector } from "react-redux";
import {selectParameters} from './../../../features/reportparameters/parametersSlice';
import DisplayCard from './../Displaycard';
import Grid from  "@mui/material/Grid";
import Error from "../../Error";
import {useGetAirNumbersQuery} from './../../../features/air/airNumbersSlice';
//import { useOidcAccessToken } from '@axa-fr/react-oidc';


   
const AirNumberCards=(props)=>{
    //const {accessToken} = useOidcAccessToken()
    const para=useSelector(selectParameters);
    const {   
            data,
            isLoading,
            isSuccess,
            isError,
            error
        } = useGetAirNumbersQuery(para)
//useGetAirNumbersQuery({params:para,getAccessTokenSilently:accessToken})
    let airNumbers=[];
    
        if(isSuccess) 
        { 
           airNumbers=data['entities']['undefined']
        }

    let cards=[];
    let ikey = 1
 
    let content=[];

        if (isLoading) {
            content = <p>"Loading..."</p>;
            } 
            else if (isSuccess && airNumbers) {

                airNumbers.forEach(el=>{

                for (const [key, value] of Object.entries(el)) {
                    ikey++
                    let string1 =key;
                    let string2="";
                    let stringIcon="";
                    let stringvalue='0';
                    
                    switch(string1.toLowerCase()){
                                case "trips":

                                    string2="Number of trips taken whether single or multi segment";
                                    stringIcon="air";
                                    stringvalue=el.trips
                                    break;
                                case "segments":
                                    string2="Total number of segments purchased";
                                    stringIcon="air";
                                    stringvalue=el.segments
                                    break;
                                case "refunds":
                                    string2="Total amount of refunds";
                                    stringIcon="refund";
                                    stringvalue=el.refunds
                                    break;
                                case "changes":
                                    string2="Number of tickets with changes made";
                                    stringIcon="change";
                                    stringvalue=el.changes
                                    break;
                                default: 
                                    string2="";
                            }

                cards.push(
                            <Grid key={ikey} item lg={3} sm={6} xl={3} xs={12} style={{width:'100%'}}>
                                <DisplayCard  headtext={string1} subtext={string2} value={stringvalue} percent={'50'} sx={{ height: '100%' }}/>
                            </Grid>
                        )
                } content=cards});
          

            }    
        else if (isError) {
        
            return(<Error ErrorMsg={error}/>);
            }
     
    return(content?content:null)
}






  export default AirNumberCards;