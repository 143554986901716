import { useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';


const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  spacing:5,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 200
  }
}));

const DashboardLayout = (props) => {
  const { children } = props;
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  return (
    <>
      <DashboardLayoutRoot>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'row',
            padding:5,
            width: '100%',
            height:'100%'
          }}
        >
          {children}
        </Box>
      </DashboardLayoutRoot>
     
    </>
  );
};

export default DashboardLayout;