import { useSelector } from 'react-redux';
import {selectParameters} from '../../../../features/reportparameters/parametersSlice';
import {useGetTotalAirTripsMonthlyTrendQuery} from '../../../../features/air/airTotalTripsAreaMonthlyTrendSlice';
import {interpolateColors} from './../../../../utils/colorgenerator';
import Error from '../../../Error';
import * as d3 from 'd3';

import { Line } from 'react-chartjs-2';

import { Box,  Card, CardContent, CardHeader, Divider } from '@mui/material';

  
  const TotalAirTripsAreaTrendLine=()=> {

  const para=useSelector(selectParameters);

  const colorScale = d3.interpolateInferno;
  
  const colorRangeInfo = {
    colorStart: 0,
    colorEnd: 1,
    useEndAsStart: false,
  };


  const {
      data,
      isLoading,
      isSuccess,
      isError,
      error
    } =useGetTotalAirTripsMonthlyTrendQuery(para);

            let dataState=[];
            let labels=[];
            let domData=[];
            let norData=[];
            let eurData=[];
            let intData=[];
            let arrDataSetsForChart=[]; //this will hold the individual data arrays for each line on the chart
            let colorsDynamic=[];
            let dataLengthForColor=0


    if (isSuccess && data){
              dataState=data['entities']['undefined']

              //we know we have data so begin processing
              labels =  dataState.map(d=>(d.yrMonth));

              domData=dataState.map(d=>(d.dom));
              if(domData){domData.labelName='Domestic'; arrDataSetsForChart.push(domData)};

              norData=dataState.map(d=>(d.nordic));
              if(norData){norData.labelName='Nordic'; arrDataSetsForChart.push(norData)};

              eurData=dataState.map(d=>(d.europe));
              if(eurData){ eurData.labelName='Europe';arrDataSetsForChart.push(eurData)};

              intData=dataState.map(d=>(d.interCon));
              if(intData){intData.labelName='Inter Con'; arrDataSetsForChart.push(intData)};

              dataLengthForColor=arrDataSetsForChart.length;

              colorsDynamic=interpolateColors(dataLengthForColor,colorScale, colorRangeInfo)
            }
            else{

            } 

      const options = {
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
          /*  title: {
              display: true,
              text: 'Flights',
            }, */
          },
        };

          

      const chartData = {
        labels,
        datasets:arrDataSetsForChart.map(ds=>(
          {
            label:ds.labelName,
            data: ds,
            borderColor: colorsDynamic, //color of the lines
            backgroundColor: colorsDynamic, // colors in legend
            lineTension: 0.5
          }

        ))
      };
          
          return (
              <Card>
                <CardHeader title="Trips Area Trend"/>
                <Divider />
                <CardContent>
                  <Box
                   sx={{
                    height: 380,
                    position: 'relative'}}>

                     {isError?<Error ErrorMsg={error}/>: <Line options={options} data={chartData} />}

                  </Box>
                </CardContent>
              </Card>
          )
}

export default TotalAirTripsAreaTrendLine