import {useOidc} from "@axa-fr/react-oidc";
import {selectShowSpinner,setSpinner} from './../../features/reportparameters/spinnerSlice';
import { useDispatch } from "react-redux";
import {useSelector} from 'react-redux';
import {Button} from '@mui/material';
import LoadSpinner from "../../utils/Spinner";


export  const LogInButton=()=>  {

const showSpinner=useSelector(selectShowSpinner);
const { login, logout, isAuthenticated} = useOidc();
const dispatch=useDispatch();

const handleLogIn=()=>{
  dispatch(setSpinner(true));
  login('/home')
}

  return (
    <>
         {showSpinner?<LoadSpinner isLoading={showSpinner} loadMsg={"Just a moment.."}/>:null}
      <Button
          onClick={()=>handleLogIn()}
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 ,backgroundColor:'#02175A'}}>
          Sign In
      </Button>
<p></p>
      {isAuthenticated &&  <Button
        onClick={()=>logout('/home')}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2,backgroundColor:'#02175A'}}>
        Sign Out
        </Button>}

    </>
  );
}
export default  LogInButton