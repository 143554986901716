import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

function Spinner(props) {

  return (
     
    <Box position="relative" display="flex" 
    alignItems="center"
    justifyContent="center">
      <Stack spacing={3}>
        <Typography variant="h5" component="div" color="textSecondary">{props.msg}</Typography>
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      </Stack>
    </Box>

  );
}

export default Spinner;