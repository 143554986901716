
import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';
import {apiSlice} from '../api/apiSlice';

//api/MiswebApi/Misweb/

const dashNumbersAdapter=createEntityAdapter();

const initialState=dashNumbersAdapter.getInitialState()

export const dashNumbersApiSlice=apiSlice.injectEndpoints({

    endpoints:builder=>({
        getDashNumbers:builder.query({
            query:(arg)=>({
                    url:'GetDashNumbers',
                    method:'POST',
                    body:arg}),
            transformResponse:responseData=>{
                return dashNumbersAdapter.setAll(initialState,responseData)
            },
            providesTags: ['DashNumbers']
        })
    })

})

export const {
    useGetDashNumbersQuery
}=dashNumbersApiSlice

export const selectDashNumbersResult=dashNumbersApiSlice.endpoints.getDashNumbers.select();

const selectDashNumbersData=createSelector(
    selectDashNumbersResult,
    dashNumbersResult=>dashNumbersResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring

export const {
    selectAll:selectAllDashNumbers
}=dashNumbersAdapter.getSelectors(state=>selectDashNumbersData(state)??initialState)

//used in front dashboard Cards