
import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';
import {apiSlice} from '../api/apiSlice';

//api/MiswebApi/Misweb/

const hotelChainsTop5Adapter=createEntityAdapter();

const initialState=hotelChainsTop5Adapter.getInitialState()

export const hotelChainsTop10ApiSlice=apiSlice.injectEndpoints({

    endpoints:builder=>({
      
        getHotelChainsTop10:builder.query({
            query:(arg)=>({
                    url:'GetHotelChainsTop10',
                    method:'POST',
                    body:arg
                }),
            transformResponse:responseData=>{

                return hotelChainsTop5Adapter.setAll(initialState,responseData)
            },
            providesTags: ['Top10HotelChains']
        })
    })
})

export const {
    useGetHotelChainsTop10Query
}=hotelChainsTop10ApiSlice



export const selectHotelChainsTop10Result=hotelChainsTop10ApiSlice.endpoints.getHotelChainsTop10.select();

const selectHotelChainsTop10Data=createSelector(
    selectHotelChainsTop10Result,
    hotelChainsTop10Result=>hotelChainsTop10Result.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring

export const {
    selectAll:selectHotelChainTop10
}=hotelChainsTop5Adapter.getSelectors(state=>selectHotelChainsTop10Data(state)??initialState)