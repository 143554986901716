import { Container,Box,Typography } from '@mui/material';

const Home=()=>{

   

      return(
        <Container component="main" maxWidth="xs" >
          <Box
            sx={{
              marginTop: 12,
              marginLeft:10,
              display: 'inline',
              flexDirection: 'column',
              alignItems: 'center',}}>
                <Box m={2} pt={3}>
                  <Typography variant="h5">Nordic MIS.</Typography>
                </Box>
                <Box m={2} pt={2}>
                  <Typography variant="body" display="block">
                    Please fill relevant parameters found on the left of your screen and click SAVE. 
                  </Typography>
                </Box>
                <Box m={2} pt={2}>
                  <Typography variant="body" display="block">
                    You can then choose from the report categories above.
                  </Typography>
                </Box>

          </Box>
        </Container>)
            
  }

  export default  Home;