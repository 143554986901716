
import { useSelector } from 'react-redux';
import {selectParameters} from './../../../../features/reportparameters/parametersSlice';
import {useGetTotalAirTripsMonthlyQuery} from '../../../../features/air/airTotalTripsMonthlySlice';

import Bars  from './../../Bars';
import Error from '../../../Error';



export const  AirMonthlyTripsBars =(props)=>{
  const para=useSelector(selectParameters);

    const{
        data,
        isLoading,
        isSuccess,
        isError,
        error
      }=useGetTotalAirTripsMonthlyQuery(para);
    
    let dataState=[]
   
      if(isSuccess) 
        { 
          dataState=data['entities']['undefined']
        }

    let chartdata1=[];
    let chartdata2=[];
    let chartLabels=[];

  

    if(isSuccess && dataState) 
      { 
        const thisYear = parseInt(para['fromdate'].substring(0,4));
        const lastYear = parseInt(thisYear - 1);
 
        chartdata1=dataState.filter((d)=>d.relevantyear===thisYear).map(d=>d.nrTrips);
        chartdata2=dataState.filter((d)=>d.relevantyear===lastYear).map(d=>d.nrTrips);

        chartLabels=dataState.filter((d)=>d.relevantyear===2022).map(d=>parseInt(d.yrMonth.substring(4,10)));
        console.log(dataState)
  console.log(chartdata1)
  console.log(chartdata2)
      }


    if(isLoading)
      {return (<p>...Loading</p>)}
    else if (isSuccess && dataState) 
      {
        return (
          <Bars title={'Trips Current-Previous'} chartdata1={chartdata1} chartdata2={chartdata2} labeldata={chartLabels} devices={null}/>
        )
      }
      else
        {
          return(<Error ErrorMsg={error}/>);
        }
}

export default AirMonthlyTripsBars;