import { Typography,Paper,Button} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useOidc } from "@axa-fr/react-oidc";

/* const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth:"500px",
    width: "100%",
    padding: "50px",
    fontSize: "20px"
  },  
}; */

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)

export const TimeoutWarningModal = ({isOpen, onRequestClose}) => {  
    const { logout} = useOidc();
    const onLogOffCall = () => {
    // Implement your logout functionality here, e.g. clear the users login cache or hit your signout server
      logout('/home')
  }

  const DisplayCard =(props)=>{

    return (
        <Paper elevation={0}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            You're being timed out due to inactivity. Please choose to stay signed in or to logoff. Otherwise, you will be logged off automatically.
            </Typography>
        </Paper>
    )
  }
  return (
        <Dialog
            open={isOpen}>
            <DialogTitle sx={{fontSize:'1rem'}}>Session Timeout</DialogTitle>
                <DialogContent>
                    <DisplayCard />
                    <Button onClick={onLogOffCall}>Log off</Button>
                    <Button onClick={onRequestClose}>Stay Logged In</Button>
                </DialogContent>
        </Dialog>    
  );
}