import Box  from '@mui/material/Box'; 
import Card  from '@mui/material/Card'; 
import CardContent  from '@mui/material/CardContent'; 
import Grid  from '@mui/material/Grid'; 
import Typography  from '@mui/material/Typography'; 


 const DisplayCard = (props) => (

  <Card >
    <CardContent  variant="alan">
      <Grid
        container
        spacing={3}
        sx={{ justifyContent: 'space-between'}}
      >
        <Grid item>
          <Typography color="white" gutterBottom variant="overline">
            {props.headtext}
          </Typography>
          <Typography color="white" variant="h4">
           {props.value}
          </Typography>
        </Grid>
        <Grid item> 
        {/*  <Avatar
            sx={{
              background:'transparent',
              height: 54,
              width: 54
            }}
          >
           
          </Avatar>  */}
        </Grid>
      </Grid>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          pt: 2
        }}
      >
        <Typography
          color="textSecondary"
          variant="caption"
        >
         {props.subText}
        </Typography>
      </Box>
    </CardContent>
  </Card>
);
export default DisplayCard