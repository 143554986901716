import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';

import { apiSlice } from '../api/apiSlice';


const airTicketTypesAdapter = createEntityAdapter();

const initialState= airTicketTypesAdapter.getInitialState();


export const airTicketTypesApiSlice=apiSlice.injectEndpoints({
    endpoints:builder=>({
        getAirTicketTypes:builder.query({
            query:(arg)=>({
                url:'GetAirTicketTypes',
                method:'POST',
                body:arg
            }),
            transformResponse:responseData=>{
             
                return airTicketTypesAdapter.setAll(initialState,responseData)
            },
            providesTags:['AirTicketTypes']
        })
    })

})

export const {useGetAirTicketTypesQuery}=airTicketTypesApiSlice;

export const selectGetTotalAirTripsAreaResult= airTicketTypesApiSlice.endpoints.getAirTicketTypes.select();

const selectGetTotalAirTripsAreaData=createSelector(
    selectGetTotalAirTripsAreaResult,
    totalAirTripsAreaResult=>totalAirTripsAreaResult.data
)

export const{
    selectAll:selectTotalAirTripsArea,
}=airTicketTypesAdapter.getSelectors(state=>selectGetTotalAirTripsAreaData(state) ?? initialState)


//used in front dashboard Ticket Type table