import { Navigate } from "react-router-dom";
import { useOidc } from '@axa-fr/react-oidc';
import Home from './Home';

const HomePage =()=>{
  const {isAuthenticated} = useOidc();

  return(
    <>
      {
        isAuthenticated ? <Home/>:<Navigate to="/login"/>
      }
    </>
    )
}

export default HomePage;