import { useSelector } from 'react-redux';
import { Card,CardHeader,CardContent,Divider,Box } from '@mui/material';
import {selectParameters} from './../../../../features/reportparameters/parametersSlice';
import {useGetCityPairsQuery} from './../../../../features/air/airCityPairsSlice';

import Spinner from './../../../../utils/Spinner';
import Error from '../../../Error';
import AirCityPairTable from './Table'

const TableAirByCityPair =(props)=>{
  const para=useSelector(selectParameters);
  const {   
      data,
      isLoading,
      isSuccess,
      isError,
      error

    } = useGetCityPairsQuery(para);
 
  
    let tableData={}

    if(isSuccess) 
    { 

      tableData=data['entities']['undefined']
    }

    let content;

      if (isLoading) {
            content = <Spinner msg={'Loading'}/>;
        } else if (isSuccess && tableData) {
            content =<AirCityPairTable data={tableData}/>
        } else if (isError) {
          content = <Error ErrorMsg={error}/>;
    }
     
  return (
    <Card>
      <CardHeader title='City Pairs' />
      <Divider />
        <CardContent>
          <Box
              sx={{
                  height: 445,
                  position: 'relative'
              }}>
            {content}
          </Box>
        </CardContent>
    </Card>
)
}


 

export default TableAirByCityPair