
import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';
import {apiSlice} from '../api/apiSlice';

//api/MiswebApi/Misweb/

const airNumbersAdapter=createEntityAdapter();

const initialState=airNumbersAdapter.getInitialState()

export const airNumbersApiSlice=apiSlice.injectEndpoints({

    endpoints:builder=>({
        getAirNumbers:builder.query({
            query:(arg)=>({
                    url:'GetAirNumbers',
                    method:'POST',
                    body:arg}),
            transformResponse:responseData=>{
                return airNumbersAdapter.setAll(initialState,responseData)
            },
            providesTags: ['AirNumbers']
        })
    })

})

export const {
    useGetAirNumbersQuery
}=airNumbersApiSlice

export const selectAirNumbersResult=airNumbersApiSlice.endpoints.getAirNumbers.select();

const selectAirNumbersData=createSelector(
    selectAirNumbersResult,
    airNumbersResult=>airNumbersResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring

export const {
    selectAll:selectAllAirNumbers
}=airNumbersAdapter.getSelectors(state=>selectAirNumbersData(state)??initialState)