import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CardNumbers from '../../components/Card/CardDashNumbers';
import Top5AirlineDoughnut from '../../components/Charts/Air/Top5AirlinesDoughNut'
import Top5HotelsDoughnut from '../../components/Charts/Hotel/Top5HotelDoughnut';
import Top10ChainsDoughnut from '../../components/Charts/Hotel/Top10ChainDoughnut';
import Top5HotelCitiesDoughnut from '../../components/Charts/Hotel/Top5CitiesDoughnut';


import TotalAirTripsAreaTrendLine from './../../components/Charts/Air/TotalAirTripsAreaTrendLine'
import {AirTripsAreaPie} from '../../components/Charts/Air/AirTripsAreaPie'
import {AirMonthlyTripsBars} from './../../components/Charts/Air/AirTripsMonthlyBar'
import AdvanDaysPie from './../../components/Charts/Air/AdvDaysPie';

import CountryLevelMapData  from  '../../components/Maps/CountryLevelMap'

import TktBreakdownTable from '../../components/Tables/Dashboard/Air/TicketType';
import DashBCO2Table from './../../components/Tables/Dashboard/Air/CO2'

import TableHotelChainsTop10 from './../../components/Tables/Dashboard/Hotel/ChainsTop10';


const Dashb = () => {
     return (   
        <Container maxWidth={false} >
        <Grid
          container
          spacing={2}
          alignContent>
            {
                <CardNumbers/>
             
            }
              <Grid
                item
                lg={4}
                md={4}
                xl={4}
                xs={4}><TktBreakdownTable  sx={{ display: 'flex'}}/></Grid>
              <Grid 
                item 
                lg={8}
                md={8} 
                xl={8}><CountryLevelMapData/></Grid> 
       
              <Grid
                item
                lg={6}
                md={6}
                xl={6}
                xs={6}><AirMonthlyTripsBars sx={{ height: 'flex' }}/></Grid>
              <Grid
                item
                lg={4}
                md={6}
                xl={3}
                xs={12}><AirTripsAreaPie /></Grid>
              <Grid
                item
                lg={4}
                md={6}
                xl={3}
                xs={12}><Top5AirlineDoughnut/></Grid>
              <Grid
                item
                lg={4}
                md={6}
                xl={3}
                xs={12}><DashBCO2Table/></Grid>
              <Grid
                item
                lg={4}
                md={6}
                xl={3}
                xs={12}><AdvanDaysPie/></Grid>
          
              <Grid
                item
                lg={6}
                md={6}
                xl={6}
                xs={6}><TotalAirTripsAreaTrendLine/></Grid>
            
              <Grid
                item
                lg={4}
                md={6}
                xl={3}
                xs={12}><Top5HotelsDoughnut/></Grid>
              <Grid
                item
                lg={4}
                md={6}
                xl={3}
                xs={12}><Top10ChainsDoughnut/></Grid>
              <Grid
                item
                lg={4}
                md={6}
                xl={3}><Top5HotelCitiesDoughnut/></Grid>
              <Grid
                    item
                    lg={4}
                    md={6}
                    xl={3}><TableHotelChainsTop10/></Grid>
             
        </Grid>
        </Container>
     

    );
};

export default Dashb;