import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';
import {apiSlice} from '../api/apiSlice';

//api/MiswebApi/Misweb/

const hotelsAdapter=createEntityAdapter();

const initialState=hotelsAdapter.getInitialState()

export const hotelsApiSlice=apiSlice.injectEndpoints({

    endpoints:builder=>({
        getHotels:builder.query({
            query:(arg)=>({
                    url:'GetHotels',
                    method:'POST',
                    body:arg
                }),
            transformResponse:responseData=>{
           
                return hotelsAdapter.setAll(initialState,responseData)
            },
            providesTags: ['Hotels']
        })
    })
})

export const {
    useGetHotelsQuery
}=hotelsApiSlice

export const selectHotelsResult=hotelsApiSlice.endpoints.getHotels.select();

const selectHotelsData=createSelector(
        selectHotelsResult,
        hotelsResult=>hotelsResult.data // normalized state object with ids & entities
    )

//getSelectors creates these selectors and we rename them with aliases using destructuring

export const {
        selectAll:selectHotels
    }=hotelsAdapter.getSelectors(state=>selectHotelsData(state)??initialState)


