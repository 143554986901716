import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';

import { apiSlice } from '../api/apiSlice';


const airTotalTripsAreaAdapter = createEntityAdapter();

const initialState= airTotalTripsAreaAdapter.getInitialState();


export const  totalAirTripsAreaApiSlice=apiSlice.injectEndpoints({
    endpoints:builder=>({
        getTotalAirTripsArea:builder.query({
            query:(arg)=>({
                url:'GetAirTotalTripsArea',
                method:'POST',
                body:arg
            }),
            transformResponse:responseData=>{
             
                return airTotalTripsAreaAdapter.setAll(initialState,responseData)
            },
            providesTags:['TotalAirTripsArea']
        })
    })

})


export const {useGetTotalAirTripsAreaQuery}=totalAirTripsAreaApiSlice;

export const selectGetTotalAirTripsAreaResult= totalAirTripsAreaApiSlice.endpoints.getTotalAirTripsArea.select();

const selectGetTotalAirTripsAreaData=createSelector(
    selectGetTotalAirTripsAreaResult,
    totalAirTripsAreaResult=>totalAirTripsAreaResult.data
)

export const{
    selectAll:selectTotalAirTripsArea,
}=airTotalTripsAreaAdapter.getSelectors(state=>selectGetTotalAirTripsAreaData(state) ?? initialState)