
import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';
import {apiSlice} from '../api/apiSlice';

//api/MiswebApi/Misweb/

const hotelCitiesTop5Adapter=createEntityAdapter();

const initialState=hotelCitiesTop5Adapter.getInitialState()

export const hotelCitiesTop5ApiSlice=apiSlice.injectEndpoints({

    endpoints:builder=>({
        getHotelCitiesTop5:builder.query({
            query:(arg)=>({
                    url:'GetHotelCitiesTop5',
                    method:'POST',
                    body:arg
                }),
            transformResponse:responseData=>{
                return hotelCitiesTop5Adapter.setAll(initialState,responseData)
            },
            providesTags: ['Top5HotelCities']
        })
    })
})

export const {
    useGetHotelCitiesTop5Query
}=hotelCitiesTop5ApiSlice



export const selectHotelCitiesTop5Result=hotelCitiesTop5ApiSlice.endpoints.getHotelCitiesTop5.select();

const selectHotelCitiesTop5Data=createSelector(
    selectHotelCitiesTop5Result,
    hotelCitiesTop5Result=>hotelCitiesTop5Result.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring

export const {
    selectAll:selectHotelCitiesTop5
}=hotelCitiesTop5Adapter.getSelectors(state=>selectHotelCitiesTop5Data(state)??initialState)