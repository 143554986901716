
import {useGetHotelChainsTop10Query} from './../../../../../features/hotel/hotelChainsTop10Slice';
import {useSelector} from 'react-redux';
import {Card,CardHeader,Divider,CardContent,Box} from '@mui/material';

import {selectParameters} from './../../../../../features/reportparameters/parametersSlice';
import HotelChainsTop10Table from './Table';
import Spinner from './../../../../../utils/Spinner';
import Error from '../../../../Error';

const TableHotelChainsTop10 =(props)=>{
  const para=useSelector(selectParameters);
  const {   
    data,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetHotelChainsTop10Query(para);

  let tableData={}

    if(isSuccess) 
    { 

      tableData=data['entities']['undefined']

    }

    let content;

    if (isLoading) {
          content = <Spinner msg={'Loading'}/>;
      } else if (isSuccess && tableData) {
          content = <HotelChainsTop10Table data={tableData}/>
      } else if (isError) {
          content = <Error erErrorMsg={error}/>;
  }


    return  ( 
      <Card   sx={{ minWidth: 275,background:'#ebebeb' }}>
            <CardHeader title='Hotel Top Chains'  sx={{background:'#999999',  color: 'white'}}  />
            <Divider />
              <CardContent   sx={{background:'#ebebeb' }}>
                <Box
                    sx={{
                        height: 300,
                        position: 'relative'
                    }}>
                  {content}
                </Box>
              </CardContent>
      </Card>
     )
}

export default TableHotelChainsTop10;
