import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import { useOidcAccessToken} from "@axa-fr/react-oidc";
import { useSelector } from "react-redux";
import {selectParameters} from './../../../features/reportparameters/parametersSlice';

import whitedot from './../../../icons/whitecircle2.png'



const RoleBasedUserMenu =(props)=>{

const para=useSelector(selectParameters);
const { accessTokenPayload} = useOidcAccessToken();

const mystyle = {
  color: "white",
  backgroundColor: "DodgerBlue",
  padding: "10px",
  fontFamily: "Arial"
};

const handleRouteChange=(route)=>{
 
  if (route === "air" ||
      route === "hotel" ||
      route === "dashboard") 
      {
        if(para.corpcode === "" || para.fromdate === "" || para.todate === ""){
        route="noparameter"; 
        }
      }
    props.navigateToRoute(route);
}

const text = {
  fontSize: '1.3rem'
};

const userRole=accessTokenPayload.role;

  let newArr=props.menuitems.filter((item)=>  {
  return  item.role.includes(userRole,0)
  }

  )


    return( newArr.map((item,index)=>{   
                          return(  
                                <ListItem
                                      button
                                      key={index}
                                      onClick={() => handleRouteChange(item.route)}>
                                        <ListItemAvatar     sx={{padding:0,marginRight:0}}>
                                          <Avatar  sx={{ width: 15, height: 15 }}>
                                          <img src={whitedot} alt="dot" width = "15" height = "15" />
                                          </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                          sx={{paddingLeft:0}}
                                          primaryTypographyProps={{ style:text }}
                                          primary={item.label}/>
                                </ListItem>
                          )
    }))
}

export default RoleBasedUserMenu