

export const mainNavbarItems = [
   
    {
        id: 0,

        label: 'Dashboard',
        route: 'dashboard',
        role: ['Admin','Reportuser','clientmanager']
    },
    {
        id: 1,

        label: 'Air',
        route: 'air',
        role:  ['Admin','Reportuser','clientmanager']
    },
    {
        id: 2,

        label: 'Hotel',
        route: 'hotel',
        role: ['Admin','Reportuser','clientmanager']
    },
    
    {
        id: 3,

        label: 'Admin',
        route: 'admin',
        role:['Admin']
    },
    {
        id: 5,

        label: 'Home',
        route: 'home',
        role:  ['Admin','Reportuser','clientmanager']
    },
    {
        id: 7,

        label: 'Sign in/out',
        route: 'login',
        role: ['Admin','Reportuser','clientmanager']
    },
    {
        id: 6,

        label: 'Profile',
        route: 'profile',
        role: ['Admin']
    },
    
   
]

export default mainNavbarItems;