import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';

import { apiSlice } from '../api/apiSlice';


const airDestinationsAdapter = createEntityAdapter();

const initialState= airDestinationsAdapter.getInitialState();


export const  airDestinationsApiSlice=apiSlice.injectEndpoints({
    endpoints:builder=>({
        getAirDestinationsMap:builder.query({
            query:(arg)=>({
                url:'GetAirDestinations',
                method:'POST',
                body:arg
            }),
            transformResponse:responseData=>{
         
            let i=1
            let airDest={}
            if (responseData['dsAirDestinations']){
                airDest['dsAirDestinations']=responseData['dsAirDestinations'].filter(dest=>(dest.trips >5)).map(dest=>{
                    let avg= Math.floor(parseInt(dest.spend)/parseInt(dest.trips));
                    //nothing worked unless I added this generated id field.. wtf!
                    let x=i++
                    return {"id":x,"name":dest.city,"coordinates":[dest.longDec,dest.latDec],"trips":dest.trips,"spend":dest.spend,avg:avg}
                })
            }
            
                return airDestinationsAdapter.setAll(initialState,airDest)
            },
            providesTags:['AirDestinationsMap']
        })
    })

})

export const {useGetAirDestinationsMapQuery}=airDestinationsApiSlice;

export const selectAirDestinationsMapResult= airDestinationsApiSlice.endpoints.getAirDestinationsMap.select();

const selectGetAirDestinationsMapData=createSelector(
    selectAirDestinationsMapResult,
    airDestinationsMapResult=>airDestinationsMapResult.data
)

export const{
    selectAll:selectAirDestinationsMap,
}=airDestinationsAdapter.getSelectors(state=>selectGetAirDestinationsMapData(state) ?? initialState)

