
import {createSelector,createEntityAdapter} from '@reduxjs/toolkit';

import {apiSlice} from '../api/apiSlice';



const airSegmentDetailAdapter=createEntityAdapter();

const initialState=airSegmentDetailAdapter.getInitialState();



export const airSegmentDetailApiSlice=apiSlice.injectEndpoints({
    
    endpoints:builder=>({
        getAirSegmentDetail:builder.query({
            query:(arg)=>({
                    url:'GetAirSegmentsDetail',
                    method:'POST',
                    body:arg
                }),
            transformResponse: responseData => {
         
                return airSegmentDetailAdapter.setAll(initialState,responseData)
                } ,
                providesTags: ['AirSegmentDetail']
                
    })})
})
//api/MiswebApi/Misweb/

export const {useGetAirSegmentDetailQuery} = airSegmentDetailApiSlice

// returns the query result object
export const selectAirSegmentDetailResult = airSegmentDetailApiSlice.endpoints.getAirSegmentDetail.select()

// Creates memoized selector
const selectGetAirSegmentDetailData = createSelector(
    selectAirSegmentDetailResult,
    airSegmentDetailResult => airSegmentDetailResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAirSegmentDetail,
  
} = airSegmentDetailAdapter.getSelectors(state => selectGetAirSegmentDetailData(state) ?? initialState)
