import { useSelector } from 'react-redux';
import {selectParameters} from './../../../../../features/reportparameters/parametersSlice';
import { Card,CardHeader,CardContent,Divider,Box } from '@mui/material';
import {useGetAirCO2Query} from '../../../../../features/air/airCO2Slice';
import Spinner from './../../../../../utils/Spinner';
import Error from '../../../../Error';

import CO2Table from './Table'


const DashBCO2Table=(props)=>{
  const para=useSelector(selectParameters);

  const {   
      data,
      isLoading,
      isSuccess,
      isError,
      error
    } = useGetAirCO2Query(para);


  let tableData={}

  if(isSuccess) 
    { 

      tableData=data['entities']['undefined']
    }

  let content;

    if (isLoading) {
          content = <Spinner msg={'Loading'}/>;
      } else if (isSuccess && tableData) {
          content =<CO2Table data={tableData}/>
      } else if (isError) {
          content = <Error erErrorMsg={error}/>;
    }


  return (
    <Card  sx={{ minWidth: 275,background:'#ebebeb' }}>
      <CardHeader title='Air CO2'  sx={{background:'#999999',  color: 'white'}}  />
      <Divider />
          <CardContent  sx={{background:'#ebebeb' }} >
              <Box
                  sx={{
                      height: 300,
                      position: 'relative'
                  }}>
                {content}
              </Box>
          </CardContent>
    </Card>
    )
  }

export default DashBCO2Table