
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


import { styled } from '@mui/material';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const TktTypeTable=(props)=>{

    let data=props.data;
    return (

        <Table  size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
          
              <TableCell align="left">Type</TableCell>
              <TableCell align="right">Segments</TableCell>
              <TableCell align="right">Spend</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row,index) => (
           <StyledTableRow key={index}>
                <TableCell align="left">{row.ticketType}</TableCell>
                <TableCell align="right">{row.totalSectors}</TableCell>
                <TableCell align="right">{row.totalSpend}</TableCell>
          
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>



    );
  }
export default TktTypeTable