import { useSelector } from 'react-redux';
import {selectParameters} from './../../../../features/reportparameters/parametersSlice';
import {useGetTotalAirTripsAreaQuery} from '../../../../features/air/airTotalTripsAreaSlice';
import Pies  from './../../Pie';
//import Doughnuts  from './../../Doughnuts';
import Spinner from './../../../../utils/Spinner'
import Error from '../../../Error';


export const  AirTripsAreaPie =()=>{

  const para=useSelector(selectParameters);

  const{
        data,
        isLoading,
        isSuccess,
        isError,
        error
    }=useGetTotalAirTripsAreaQuery(para);

  let dataState=[]
    if(isSuccess) 
    { 
      dataState=data['entities']['undefined']
    }

  let chartData=[];
  let chartLabels=[];
  let devices=[];


    if(isSuccess && dataState) 
    { 

      chartData=dataState.map(d=>(d.trips))
      chartLabels=dataState.map(d=>(d.area ));
      devices=dataState.map(d=>({"title":d.area,"value":d.trips,"color":'red'}));
    }
  

    if(isLoading)
    {
        return (<Spinner msg={'Loading'}/>)
    }
      else if (isSuccess && dataState) 
          {
              return (<Pies title={'Area Trips'} chartData={chartData} labeldata={chartLabels} devices={null}/>)
          }
      else
          {
            return(<Error ErrorMsg={error}/>);
          }
}

export default AirTripsAreaPie;