import {Component} from 'react';
import { Button } from '@mui/material';
import { writeFile,utils } from 'xlsx';



class DataToExcel extends Component{
    constructor(props){
        super(props);
      this.state=({
        icon: "calendar"
     
      })

        this.exportFile=this.exportFile.bind(this);
    }

    exportFile() {
        /* convert state to workbook */
        const newDate = new Date();
        const rightNow =newDate.toLocaleString();
        let client="Test Client" 
        let orderID="23"  
        const ws = utils.json_to_sheet(this.props.dataForExcel);
        const sheetNameExcel = this.props.fileName;
        const fileNameExcel =client + "_" + sheetNameExcel + "_" + rightNow +".xlsx";
	     
        const wb = utils.book_new();
       utils.book_append_sheet(wb, ws, sheetNameExcel);
        /* generate XLSX file and send to client */
       writeFile(wb, fileNameExcel)
    };
    
   
    render(){
   
        return(
            <div>
               <Button size="small"  color="primary" onClick={this.exportFile}>Download to Excel</Button>
            </div>
        )
    }
}


export default DataToExcel ;