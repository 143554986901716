import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { styled } from '@mui/material';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const CO2Table=(props)=>{

    let data=props.data;


    return (

        <Table  size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Area</TableCell>
              <TableCell align="right">Segs</TableCell>
              <TableCell align="right">Tot km</TableCell>
              <TableCell align="right">CO2e</TableCell>
            
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row,index) => (
                   <StyledTableRow key={index}>
                <TableCell align="left">{row.area}</TableCell>
                <TableCell align="right">{row.totalSegments}</TableCell>
                <TableCell align="right">{row.totalKM}</TableCell>
                <TableCell align="right">{row.totalCO2}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>

    );
  }


/* import { Container, CssBaseline, Grid, Typography } from "@material-ui/core";
import CustomGrid from "../../../Tables/custom-grid";


const CO2Table=(props)=>{

  let data=props.data;

  const columns = [
        {field:"data1",headerName:"Area",width:200},
        {field:"data2",headerName:"Segs",width:200},
        {field:"data3",headerName:"Tot Km",width:218},
        {field:"data4",headerName:"Co2",width:200}
    ];


  return (
        <Container maxWidth="lg">
            <CssBaseline />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                <Typography variant="subtitle1" component="h1">
                    CO2
                </Typography>
                </Grid>
                <Grid item xs={12}>
                <CustomGrid
                    rows={data}
                    columns={columns}
                    height={237}
                    width={420}
                />
                </Grid>
            </Grid>
            </Container>
  );
} */




export default CO2Table