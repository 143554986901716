import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Box  from '@mui/material/Box'; 
import Card  from '@mui/material/Card'; 
import CardHeader  from '@mui/material/CardHeader'; 
import CardContent  from '@mui/material/CardContent'; 
import Divider  from '@mui/material/Divider'; 
import { useTheme } from '@mui/material';

//ChartJS.register(ChartDataLabels)


const Doughnuts=(props)=> {
      const theme = useTheme();  
      const chartData=props.chartData;
      const labeldata=props.labeldata;
      const devices=props.devices;

      const data = {
            datasets: [
              {
                data:chartData,
                backgroundColor:['#02175A','#00408A','#0069AD','#0093BF','#00BBC0','#00E3B5'],
                borderWidth: 8,
                borderColor: '#FFFFFF',
                hoverBorderColor: '#FFFFFF'
              }
            ],
            labels:labeldata
          };
    
     
      const options = {
        animation: false,
        cutoutPercentage: 80,
        layout: { padding: 0 },
        legend: {
          display: true
        },
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
          backgroundColor: theme.palette.background.paper,
          bodyFontColor: theme.palette.text.secondary,
          borderColor: theme.palette.divider,
          borderWidth: 1,
          enabled: true,
          footerFontColor: theme.palette.text.secondary,
          intersect: false,
          mode: 'index',
          titleFontColor: theme.palette.text.primary
        },
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: false,
            text: "",
            padding: {
              bottom: 30
            },
            weight: "bold",
            color: "#00325c",
            font: {
              size: 13
            },
            align: "start"
          },
          datalabels: {
            data:labeldata,
            display: true,
            color: "white"
          }
        }
      };
    
          return (
         
              <Card>
                  <CardHeader title={props.title}/>
                  <Divider />
                      <CardContent>
                          <Box
                              sx={{
                                  height: 300,
                                  position: 'relative'
                              }}>
                            <Doughnut
                                data={data}
                                options={options}
                                plugins={[ChartDataLabels]}
                            />
                          </Box>
                
                              
                                    
                      </CardContent>
              </Card>
          )
}

export default Doughnuts;