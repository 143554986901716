import {useEffect,useState} from 'react';
import { useSelector } from 'react-redux/es/exports';
import {useGetHotelMapDestinationsQuery} from '../../../features/hotel/hotelMapDestinationsSlice';
import {selectParameters} from '../../../features/reportparameters/parametersSlice';


import Spinner from '../../../utils/Spinner'

import HotelMapResponsive from './HotelMapResponsive';


const HotelDestWorldMap = (props) => {
  const [mapData , setDataCountry]=useState(null)

  const para=useSelector(selectParameters);
 
  const {   
    data,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetHotelMapDestinationsQuery(para);

  useEffect(()=>{

    fetch("GeoChart.world.geo.json")
          .then(
            (response)=>response.json())
          .then((data)=> setDataCountry(data)
          )
    },[]
  )

  let hotelData=[]

  if(isSuccess && data) 
  { 
    hotelData=data['entities']['undefined']
 
  }



  if(isLoading)
  {
      return (<Spinner msg={'Loading Map'}/>)
  }
    else if (isSuccess && hotelData &&  mapData) 
        {

            return (<HotelMapResponsive data={hotelData} title={"Hotel Nights"} mapdata={mapData}/>)
        }
    else
        {
        return null
        }
        
      }
  
      export default HotelDestWorldMap;