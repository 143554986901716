import {useNavigate} from "react-router-dom";

import Typography from '@mui/material/Typography'
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Grid  from "@mui/material/Grid";

import RoleBasedUserMenu from "./RoleBasedUserMenu";
import JustLogIn from "./JustLoginMenu";

import {ParameterInputs} from  '../../Forms/parameterForm'

import {navbarStyles} from '../styles';
import {mainNavbarItems} from './mainNavbarItems';


import { useOidc } from "@axa-fr/react-oidc";

const Sidebar = () =>  {
  
    const navigate = useNavigate();

    const {isAuthenticated} =useOidc();
  
    const handlePageChange=(route)=>{
        switch(route){
            case "air":
                navigate(route);
                break;
            case "hotel":
                navigate(route);
                break;
            default:
                navigate(route);
            }       
        }
const content=isAuthenticated ? <RoleBasedUserMenu menuitems={mainNavbarItems} navigateToRoute={handlePageChange}/>:<JustLogIn navigateToRoute={handlePageChange}/>
 

   
    return (     
            <Drawer
                variant="permanent"
                anchor="left">
                <Grid item
                    spacing={3}
                    sx={{paddingTop:2,paddingLeft:2}}>
                    <Typography variant='h4'>
                        Nordic MIS
                    </Typography>
                </Grid>
                <Divider variant="middle"
                sx={navbarStyles.divider}/>
                  <Grid container
                    spacing={3}
                    maxHeight={450}     
                    sx={{paddingTop:2,paddingLeft:2}}>
                   <Grid >
                    <List sx={{ml:1}}>
                      {content}
                    </List>
                    </Grid></Grid>
                <Divider variant="middle"
                    sx={navbarStyles.divider}/>
                {isAuthenticated &&<Grid item
                                    spacing={3}
                                    sx={{paddingTop:2,paddingLeft:2}}>
                        <ParameterInputs/>
                    </Grid>}               
            </Drawer>          
        );
    }


    export default Sidebar;