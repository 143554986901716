
import { Container,Grid} from '@mui/material';

import TableHotelDestinations from './../../components/Tables/Hotel/Destinations';
import TableHotelChains  from './../../components/Tables/Hotel/Chains';
import TableHotels from './../../components/Tables/Hotel/Hotels';
 import HotelDestWorldMap from './../../components/Maps/HotelMap'

const Hotel = () => {
    return (
        <Container maxWidth={false}>
        <Grid
          container
          spacing={2} >
            <Grid item  lg={4}
                md={4}
                xl={4}>    
                <TableHotels/></Grid>
            <Grid item lg={8}
                 md={8} 
                 xl={8}>
                <HotelDestWorldMap /></Grid>
            <Grid item  lg={4}
                md={4}
                xl={4}>
                <TableHotelDestinations/></Grid>
            <Grid item  lg={4}
                md={4}
                xl={4}>
                <TableHotelChains/></Grid>
         
        </Grid>
        </Container>
    
    );
};

export default Hotel;