import {useEffect,useState} from 'react';
import {useSelector} from 'react-redux/es/exports';
import {useGetCountryLevelMapDataQuery} from '../../../features/dashboard/countryLevelDataSlice';
import {selectParameters} from '../../../features/reportparameters/parametersSlice';
import CountryLevelMap from  './CountryLevelMap';
import Spinner from '../../../utils/Spinner';
import Error from './../../Error'

const CountryLevelMapData =()=>{
    const [mapData , setDataCountry]=useState(null)
    const para=useSelector(selectParameters);
    const {   
        data,
        isLoading,
        isSuccess,
        isError,
        error
      } = useGetCountryLevelMapDataQuery(para);

  
    useEffect(()=>{

      fetch("GeoChart.world.geo.json")
            .then(
              (response)=>response.json())
            .then((data)=> setDataCountry(data)
            )
      },[]
    )

    let countryData=[]

if(isSuccess && data ) 
{ 
  countryData=data['entities']['undefined']
}

 let content;
/*  if (isLoading) {
  content = <Spinner msg={'Loading'}/>;
} else if(isSuccess && countryData.length >0) {
  content =<CountryLevelMap data={countryData} title={"World"} mapdata={mapData}/>
} else if(isError) {
  content = <Error ErrorMsg={error}/>;
} */

 if(isLoading)
          {
              return (<Spinner msg={'Loading Map'}/>)
          }
      else if (isSuccess && countryData.length >0 &&  mapData) 
          {
              return (<CountryLevelMap data={countryData} title={"World"} mapdata={mapData}/>)
          }
      else if (isError) 
          {
              return(<Error ErrorMsg={error}/>);
          }

} 
  export default CountryLevelMapData


